import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

import Checkout from '@/views/Checkout';
import NotFound from '@/views/NotFound';
import EcommerceCheckout from '@/views/EcommerceCheckout';

Vue.use(VueRouter);

const routes = [
  {
    path: '/checkout/:checkoutId',
    name: 'checkout',
    component: Checkout,
    meta: {
      title: 'Link de pagamento'
    }
  },

  {
    path: '/ecommerce/checkout/:checkoutId',
    name: 'ecommerceCheckout',
    component: EcommerceCheckout,
    meta: {
      title: 'Link de pagamento'
    }
  },

  {
    path: '*',
    name: 'notfound',
    component: NotFound,
    meta: {
      title: 'Página não encontrada'
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, _, next) => {
  store.commit('setFaviconPath', '/favicon.ico');

  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }

  next();
});

export default router;
