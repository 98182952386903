<template>
	<div class="payment-type" :class="{ 'disabled': isDisabled }" @click="click">
    <div class="icon-container" :class="{ 'selected': isSelected }">
      <div class="icon">
        <i :class="styles.icon"/>
      </div>
    </div>

    <div class="type">
      {{ styles.text }}

      <span v-if="label">
        {{ label }}
      </span>
    </div>
	</div>
</template>

<script>
  export default {
    name: 'PaymentType',

    props: {
      isSelected: {
        type: Boolean,
        default: false
      },

      isDisabled: {
        type: Boolean,
        default: false
      },

      label: {
        type: String,
        default: ''
      },

      styles: {
        type: Object,
        default() {
          return {
            icon: 'icon-pix',
            text: 'Text'
          };
        }
      }
    },

    methods: {
      click() {
        if (!this.isDisabled) {
          this.$emit('click');
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .payment-type {
    width: 130px;
    min-width: 100px;
    margin-right: 2rem;
    border: 1px solid #DADADA;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    .type {
      padding: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;

      span {
        font-size: 12px;
        color: #ff9c00;
        font-weight: 500;
      }
    }

    .icon-container {
      background: #EFEFEF;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px 6px 0 0;
      padding: 0.5rem;

      .icon {
        width: 45px;
        height: 45px;
        border-radius: 10rem;
        background: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          color: #BABABA;

          &.icon-pix {
            font-size: 1.8rem;
          }
        }
      }

      &.selected {
        background: #ff9c00;

        i {
          color: #ff9c00;
        }
      }
    }
  }

  @media (max-width: 520px) {
    .payment-type {
      margin-right: 1rem;
      font-size: 12px;
    }
  }

  @media (max-width: 365px) {
    .payment-type {
      width: 100%;
    }
  }
</style>