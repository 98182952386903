import moment from 'moment';
import brands from './brands';

export default {
  formatBrlAmount(amount) {
    return parseFloat(amount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  },

  removeCharacters(str) {
    return str.replace(/[^0-9]/g,'');
  },

  dateFormat(date, format = 'DD/MM/YYYY') {
    return moment(date).format(format);
  },

  toBoolean(value) {
    return value === '1';
  },

  isNull(value) {
    return value == null;
  },

  formatYear(data, currentFormat, format) {
    return moment(data, currentFormat).format(format);
  },

  isUndefined(value) {
    return value == undefined;
  },

  toUpperCase(str) {
    return str.toUpperCase();
  },

  getCardBrand(number) {
    if (!number) {
      return;
    }

    const formattedNumber = this.removeCharacters(number);
    for (var card in brands) if (formattedNumber.match(brands[card].bins)) return brands[card];

    return false;
  }
};