import Vue from 'vue';
import App from '@/App';
import router from '@/router';
import store from '@/store';
import VueWindowSize from 'vue-window-size';
import VueClipboard from 'vue-clipboard2';
import VueMeta from 'vue-meta';
import Utils from '@/services/utils';
import VueTheMask from 'vue-the-mask';
import ptBR from 'vee-validate/dist/locale/pt_BR.json';
import { extend, localize } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import * as Sentry from '@sentry/vue';

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize('pt_br', ptBR);

Vue.config.productionTip = false;

Vue.use(VueMeta);
Vue.use(VueTheMask);
Vue.use(VueWindowSize);
Vue.use(VueClipboard);

Vue.prototype.$utils = Utils;

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['checkout.impay.com.br', /^\//],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
