<template>
  <div class="notfound-container">
    <card-component
      class="card"
      :styles="cardData"
    />
  </div>
</template>

<script>
  import Card from '@/components/Card';

  export default {
    name: 'NotFound',

    data() {
      return {
        cardData: {
          icon: 'icon-checkout-error',
          backgroundIcon: '#b81d5b',
          title: 'Página não encontrada',
          subTitle: 'Entre em contato com o emissor e tente novamente!'
        }
      };
    },

    components: {
      'card-component': Card
    }
  };
</script>

<style lang="scss" scoped>
  .notfound-container {
    justify-content: center;
    display: flex;
    padding: 2rem;

    .card {
      width: 70rem;
    }
  }
</style>