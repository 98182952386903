<template>
  <div v-if="!hasGenericError" class="container">
    <spinner-component
      v-if="hasSpinner"
      :styles="spinner"
    />

    <div v-else class="checkout-container">
      <navbar-component
        v-if="!hideNavbar"
        class="header-component"
        :data="navbarData"
      />

      <div class="checkout-content">
        <div class="forms-container">
          <header-component
            :data="headerData"
          />

          <div class="checkout">
            <card-component
              v-if="isSucessfulPayment"
              :styles="successPaymentCardData"
            />

            <card-component
              v-else-if="isCheckoutExpired"
              :styles="expiredCheckoutCardData"
            />

            <card-component
              v-else-if="hasPixErrorPayment"
              :styles="errorPixPaymentCardData"
            />

            <card-component
              v-else-if="hasCreditPaymentError"
              :styles="errorCreditPaymentCardData"
            />

            <div v-else>
              <div v-if="isMultiplePayment">
                <p>
                  Escolha a melhor forma de pagamento pra você:
                </p>

                <div class="section">
                  <status-component
                    step="1"
                    :status="currentPaymentType ? 'check' : 'wait'"
                  />

                  <div class="content">
                    <payment-type-card-component
                      v-for="(item, index) in paymentTypes"
                      :key="index"
                      :isSelected="item.isActive"
                      :styles="item"
                      :isDisabled="hasRequest"
                      :label="item.value == 'pix' && onPixPaymentDiscountRate > 0 ? `${onPixPaymentDiscountRate}% OFF` : ''"
                      @click="setPaymentType(item)"
                    />
                  </div>
                </div>
              </div>

              <div v-if="isPixPayment()" class="section">
                <status-component
                  :status="emv ? 'check' : 'wait'"
                  :step="isMultiplePayment ? '2' : '1'"
                  :hasLine="false"
                />

                <pix-payment-form-component
                  :hasRequest="hasRequest"
                  :emv="emv"
                  @createPayment="startPixPayment"
                  @refresh="startPixPayment"
                />
              </div>

              <div v-else-if="isCreditPayment()">
                <div class="section">
                  <status-component
                    :step="isMultiplePayment ? '2' : '1'"
                    :status="!isPaymentButtonDisabled ? 'check' : 'wait'"
                    :hasLine="false"
                  />

                  <credit-payment-form-component
                    :amount="totalAmount"
                    :minInstallmentAmount="minInstallmentAmount"
                    :maxInstallmentQuantity="maxInstallmentQuantity"
                    :isCustomerFeeCoverage="isCustomerFeeCoverage"
                    :initialInstallmentFeeCoverage="initialInstallmentFeeCoverage"
                    :isDisable="hasRequest"
                    @form="setCreditCardData"
                    @isValid="creditCardFormObserver"
                    @paymentDetails="setCreditCardPaymentDetails"
                  />
                </div>

                <div class="payment-button-container">
                  <button-component
                    text="Pagar"
                    :hasRequest="hasRequest"
                    :disableButton="isPaymentButtonDisabled || hasRequest"
                    @click.native="createCreditPayment"
                  />
                </div>
              </div>

              <div v-else class="section">
                <status-component
                  step="2"
                  :hasLine="false"
                />

                <default-payment-form-component
                  class="content disable"
                  :amount="totalAmount"
                />
              </div>
            </div>
          </div>
        </div>

        <summary-card-component
          v-if="!isSucessfulPayment && !isCheckoutExpired"
          class="summary-card"
          :items="summaryItems"
          :rateItems="summaryRateItems"
          :amount="totalAmount"
          :observation="summaryObservation"
          :textOfInstallments="summaryTextOfInstallments"
        />
      </div>

      <footer-component
        v-if="!hideFooter"
        :data="footerData"
      />
    </div>
  </div>

  <div v-else class="error-card-container">
    <card-component
      class="error-card"
      :styles="genericErrorCardData"
    />
  </div>
</template>

<script>
  import Navbar from '@/components/base/Navbar';
  import Footer from '@/components/base/Footer';
  import Spinner from '@/components/Spinner';
  import Header from '@/components/Header';
  import Status from '@/components/Status';
  import Button from '@/components/Button';
  import PaymentTypeCard from '@/components/PaymentTypeCard';
  import DefaultPaymentForm from '@/components/ecommerce/DefaultPaymentForm';
  import CreditPaymentForm from '@/components/ecommerce/CreditPaymentForm';
  import PixPaymentForm from '@/components/ecommerce/PixPaymentForm';
  import SummaryCard from '@/components/ecommerce/SummaryCard';
  import Card from '@/components/Card';

  export default {
    name: 'EcommerceCheckout',

    components: {
      'navbar-component': Navbar,
      'footer-component': Footer,
      'spinner-component': Spinner,
      'header-component': Header,
      'status-component': Status,
      'button-component': Button,
      'default-payment-form-component': DefaultPaymentForm,
      'payment-type-card-component': PaymentTypeCard,
      'credit-payment-form-component': CreditPaymentForm,
      'pix-payment-form-component': PixPaymentForm,
      'summary-card-component': SummaryCard,
      'card-component': Card,
    },

    data() {
      return {
        hideNavbar: false,
        hideFooter: false,
        hasSpinner: false,
        hasRequest: false,

        currentPaymentType: '',
        isMultiplePayment: false,
        isPaymentButtonDisabled: true,

        hasGenericError: false,
        isSucessfulPayment: false,
        hasCreditPaymentError: false,
        hasPixErrorPayment: false,
        isCheckoutExpired: false,

        checkoutId: '',
        sellerId: '',
        emv: '',
        pollingInterval: '',

        isCustomerFeeCoverage: false,

        initialInstallmentFeeCoverage: null,
        maxInstallmentQuantity: null,
        minInstallmentAmount: null,

        summaryItems: [],
        summaryRateItems: [],
        summaryTextOfInstallments: '',
        summaryObservation: '',

        totalOriginalAmount: 0,
        totalAmount: 0,
        onPixPaymentDiscountRate: 0,

        creditCardData: {
          cardNumber: '',
          expirationDate: '',
          securityCode: '',
          fullHolderName: '',
          installments: '',
        },

        spinner: {
          size: '3px',
          color: '#FEAA3B',
          borderSize: '1.5em',
        },

        headerData: {
          text: '',
          description: {
            label: '',
            value: ''
          },
          lineColor: '#DADADA',
          textColor: '#6D6F6F',
          descriptionColor: '#6D6F6F',
        },

        navbarData: {
          backgroundColor: '',
          logoPath: 'logo-impay.png',
          isExternalLogo: false,
          borderColor: '',
          text: '',
        },

        footerData: {
          backgroundColor: '',
          name: '',
        },

        successPaymentCardData: {
          icon: 'icon-checkout-check',
          backgroundIcon: '#00d68f',
          title: 'Pagamento efetuado com sucesso!',
          transaction: {
            id: '',
            date: ''
          }
        },

        expiredCheckoutCardData: {
          icon: 'icon-checkout-error',
          backgroundIcon: '#B81D5B',
          title: 'Link de pagamento expirado!',
          subTitle: 'Mas não se preocupe, entre em contato com o emissor e solicite um novo link para efetuar seu pagamento.',
        },

        errorCreditPaymentCardData: {
          icon: 'icon-checkout-error',
          backgroundIcon: '#B81D5B',
          title: 'Não conseguimos efetuar seu pagamento no momento',
          subTitle: 'Por favor, entre em contato o com seu banco para melhor entendimento e em seguida',
          items: {
            title: 'Os erros mais comuns apresentados são:',
            itemsArray: [
              'Cartão bloqueado',
              'Compra não autorizada pelo banco',
              'Saldo/limite indisponível'
            ]
          },
          footerText: 'Caso o erro persistir, favor entrar em contato com o emissor',
          link: window.location.href,
        },

        errorPixPaymentCardData: {
          icon: 'icon-checkout-error',
          backgroundIcon: '#B81D5B',
          title: 'Não conseguimos gerar um pagamento via Pix no momento',
          subTitle: 'Por favor, espere alguns minutos e em seguida',
          link: window.location.href,
        },

        genericErrorCardData: {
          icon: 'icon-checkout-error',
          backgroundIcon: '#B81D5B',
          title: 'Encontramos um erro',
          subTitle: 'Entre em contato com o emissor ou',
          link: window.location.href,
        },

        paymentTypes: [
          {
            icon: 'icon-pix',
            text: 'Pix',
            value: 'pix',
            isActive: false,
          },

          {
            icon: 'icon-credit-card',
            text: 'Cartão',
            value: 'credit',
            isActive: false,
          },
        ]
      };
    },

    created() {
      const { hideNavbar, hideFooter } = this.$route.query;

      this.hideNavbar = !!hideNavbar;
      this.hideFooter = !!hideFooter;

      this.$store.commit('setFaviconPath', '/favicon-instmix.ico');

      this.navbarData = this.navBarStyle('#AA1428', 'logo-instMix.png', false, '#FF9C00', 'Sistema de pagamento digital');
      this.headerData = this.headerStyle('#FF9C00', '#AA1428', '#6D6F6F');
      this.footerData = this.footerStyle('#AA1428', 'Instituto Mix');

      this.setPageTitle('Instituto Mix');

      const { checkoutId } = this.$route.params;

      if (checkoutId) {
        this.$store.commit('ecommerceModule/setCheckoutId', checkoutId);
        this.fetchData();
      } else {
        this.hasGenericError = true;
      }
    },

    watch: {
      currentPaymentType() {
        this.totalAmount = this.totalOriginalAmount;
        this.summaryObservation = '';

        if (this.isPixPayment() && this.onPixPaymentDiscountRate > 0) {
          const discount = this.totalOriginalAmount * this.onPixPaymentDiscountRate / 100;
          this.totalAmount = this.totalAmount - discount;

          this.summaryObservation = `Desconto de ${this.$utils.formatBrlAmount(discount)} (-${this.onPixPaymentDiscountRate}%)`;
        }
      },
    },

    methods: {
      hasContractLabel(resource) {
        if (!resource) {
          return false;
        }

        const resources = ['CHECKOUTERP', 'CHECKOUTPRECONTRATO'];
        return resources.includes(this.$utils.toUpperCase(resource));
      },

      async fetchData() {
        this.hasSpinner = true;

        await this.$store.dispatch('ecommerceModule/getCheckout')
          .then(res => {
            const {
              is_pix_enabled,
              is_credit_enabled,
              is_paid,
              is_expired,
              initial_installment_fee_coverage,
              is_customer_fee_coverage,
              max_installments,
              seller_id,
              description,
              customer_name,
              grand_total,
              due_date,
              on_time_payment_discount_amount,
              late_fee_amount,
              daily_interest_amount,
              min_installment_amount,
              on_pix_payment_discount_rate,
              items,
              metadata,
              transaction
            } = res.data;

            this.headerData.text = customer_name;
            this.headerData.description.label = this.hasContractLabel(metadata?.resource) ? 'Contrato' : '';
            this.headerData.description.value = description;

            if (is_paid) {
              this.setSuccessfulPayment(transaction?.id);
              return;
            }

            if (is_expired) {
              this.setExpiredCheckout();
              return;
            }

            this.initPaymentTypes(is_pix_enabled, is_credit_enabled);

            const isEditoraResource = metadata?.resource && metadata?.resource == 'CHECKOUTEDITORA';
            const minInstallmentAmount = isEditoraResource ? 250 : 25;

            this.totalAmount = grand_total;
            this.totalOriginalAmount = grand_total;
            this.maxInstallmentQuantity = max_installments;
            this.initialInstallmentFeeCoverage = initial_installment_fee_coverage;
            this.minInstallmentAmount = min_installment_amount ?? minInstallmentAmount;
            this.isCustomerFeeCoverage = is_customer_fee_coverage;
            this.onPixPaymentDiscountRate = on_pix_payment_discount_rate;

            this.$store.commit('ecommerceModule/setSellerId', process.env.VUE_APP_SELLER_ID ?? seller_id);

            this.summaryItems = items.map(item => {
              const { price, description } = item;

              return {
                description: `${item.quantity}x ${description}`,
                value: this.$utils.formatBrlAmount(price),
              };
            });

            this.setSummaryRateItem('Vencimento', this.$utils.dateFormat(due_date));

            if (late_fee_amount > 0) {
              this.setSummaryRateItem('Multa', this.$utils.formatBrlAmount(late_fee_amount));
            }

            if (daily_interest_amount > 0) {
              this.setSummaryRateItem('Juros', this.$utils.formatBrlAmount(daily_interest_amount));
            }

            if (on_time_payment_discount_amount > 0) {
              this.setSummaryRateItem('Desconto', this.$utils.formatBrlAmount(on_time_payment_discount_amount));
            }
          })
          .catch(() => {
            this.hasGenericError = true;
          });

        parent.postMessage('impay-checkout-loaded', '*');

        this.hasSpinner = false;
      },

      async startPixPayment() {
        this.hasRequest = true;

        this.summaryObservation = '';
        this.summaryTextOfInstallments = '1x de ' + this.$utils.formatBrlAmount(this.totalAmount);

        await this.checkPixPayment();

        this.startPolling();

        this.hasRequest = false;
      },

      async checkPixPayment() {
        await this.$store.dispatch('ecommerceModule/getCheckout')
          .then(async res => {
            const { is_paid, is_expired, transaction } = res.data;

            if (!transaction || this.$utils.toUpperCase(transaction?.status) == 'CANCELED') {
              await this.createPixPayment(false);
              return;
            }

            if (is_paid) {
              this.setSuccessfulPayment(transaction?.id);
              return;
            }

            if (is_expired) {
              this.setExpiredCheckout();
              return;
            }

            this.emv = transaction.payment.pix.qr_code.emv;
          })
          .catch(err => {
            if (err?.data && err.data.status == 419) {
              this.setExpiredCheckout();
            }
          });
      },

      async createPixPayment() {
        const payment = JSON.stringify({
          payment: {
            method: 'Pix'
          }
        });

        await this.$store.dispatch('ecommerceModule/pay', payment)
          .then(res => {
            const { transaction } = res.data;

            this.emv = transaction.payment.pix.qr_code.emv;
          })
          .catch(() => {
            this.setPixErrorPayment();
          });
      },

      async createCreditPayment() {
        this.hasRequest = true;

        const {
          cardNumber,
          expirationDate,
          securityCode,
          fullHolderName,
          installments
        } = this.creditCardData;

        const [expirationMonth, expirationYear] = expirationDate.split('/');

        const payment = JSON.stringify({
          payment: {
            method: 'CreditCard',
            credit_card: {
              number: cardNumber,
              holder_name: fullHolderName,
              expiration_month: expirationMonth,
              expiration_year: expirationYear,
              security_code: securityCode,
              installments: {
                count: installments
              }
            }
          },
        });

        await this.$store.dispatch('ecommerceModule/pay', payment)
          .then(res => {
            const { transaction } = res.data;

            this.setSuccessfulPayment(transaction?.id);
          })
          .catch(err => {
            if (err.data && err.data.status == 419) {
              this.setExpiredCheckout();
              return;
            }

            this.setCreditPaymentError();
          });

        this.hasRequest = true;
      },

      setSuccessfulPayment(transactionId) {
        this.isSucessfulPayment = true;

        const currentDate = new Date();
        this.successPaymentCardData.transaction.id = transactionId;
        this.successPaymentCardData.transaction.date = this.$utils.dateFormat(currentDate, 'DD/MM/YYYY HH:mm:ss');
      },

      setExpiredCheckout() {
        this.isCheckoutExpired = true;
      },

      setPixErrorPayment() {
        this.hasPixErrorPayment = true;
      },

      setCreditPaymentError() {
        this.hasCreditPaymentError = true;
      },

      creditCardFormObserver(isValid) {
        this.isPaymentButtonDisabled = !isValid;
      },

      setCreditCardData(data) {
        const {
          cardNumber,
          expirationDate,
          securityCode,
          fullHolderName,
          installments,
        } = data;

        this.creditCardData.cardNumber = cardNumber;
        this.creditCardData.expirationDate = expirationDate;
        this.creditCardData.securityCode = securityCode;
        this.creditCardData.fullHolderName = fullHolderName;
        this.creditCardData.installments = installments;
      },

      setCreditCardPaymentDetails(data) {
        const { amount, originalAmount, numberOfInstallments, amountByInstallment } = data;

        this.totalAmount = amount;
        this.totalOriginalAmount = originalAmount;

        this.summaryTextOfInstallments = `${numberOfInstallments}x de ${this.$utils.formatBrlAmount(amountByInstallment)}`;
        this.summaryObservation = '';
      },

      isPixPayment() {
        return this.currentPaymentType === 'pix';
      },

      isCreditPayment() {
        return this.currentPaymentType === 'credit';
      },

      setSummaryRateItem(label, value) {
        this.summaryRateItems.push({
          label,
          value,
        });
      },

      initPaymentTypes(isPixEnabled, isCardEnabled) {
        if (isPixEnabled && isCardEnabled) {
          this.isMultiplePayment = true;
          return;
        }

        if (isPixEnabled) {
          this.currentPaymentType = 'pix';
        }

        if (isCardEnabled) {
          this.currentPaymentType = 'credit';
        }
      },

      setPaymentType(item) {
        if (item.isActive) {
          return;
        }

        this.paymentTypes.map(item => item.isActive = false);

        item.isActive = true;
        this.currentPaymentType = item.value;

        this.resetPayment();
      },

      setPageTitle(complement) {
        window.document.title = this.$router.history.current.meta.title + ' | ' + complement;
      },

      navBarStyle(backgroundColor, logoPath, isExternalLogo = false, borderColor, text) {
        return {
          backgroundColor,
          logoPath,
          borderColor,
          text,
          isExternalLogo,
        };
      },

      headerStyle(lineColor, textColor, descriptionColor) {
        return {
          text: '',
          description: {
            label: '',
            value: ''
          },
          lineColor: lineColor,
          textColor: textColor,
          descriptionColor: descriptionColor,
        };
      },

      footerStyle(backgroundColor, name, fontColor) {
        return {
          backgroundColor,
          name,
          fontColor,
        };
      },

      resetPayment() {
        this.summaryTextOfInstallments = '';
        this.totalAmount = this.totalOriginalAmount;
        this.resetPixPayment();
      },

      resetPixPayment() {
        this.emv = '';
        this.resetPolling();
      },

      startPolling() {
        this.pollingInterval = setInterval(async () => {
          await this.checkPixPayment();
        }, 5000);
      },

      resetPolling() {
        clearInterval(this.pollingInterval);
        this.pollingInterval = null;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .container {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    min-height: 100vh;

    .checkout-container {
      flex-direction: column;
      align-items: center;
      min-height: 100vh;
      display: flex;
      width: 100%;

      .header-component {
        width: 100%;
      }

      .checkout-content {
        margin-bottom: 2rem;
        padding: 0 1.5rem;
        margin-top: 3rem;
        display: flex;
        width: 1440px;

        .forms-container {
          width: 100%;

          .checkout {
            p {
              font-size: 16px;
            }

            .section {
              margin-top: 2rem;
              display: flex;

              .content {
                display: flex;
                width: 100%;

                &.disable {
                  pointer-events: none;
                  filter: blur(1.5px);
                  user-select: none;
                  opacity: 0.4;
                }
              }
            }

            .payment-button-container {
              margin-top: 1.5rem;

              button {
                margin-left: auto;
                border-radius: 0.5rem;
              }
            }
          }
        }

        .summary-card {
          max-width: 450px;
          width: 100%;
          margin-left: 1.5rem;
        }
      }
    }
  }

  .error-card-container {
    justify-content: center;
    padding: 2rem;
    display: flex;

    .error-card {
      width: 70rem;
    }
  }

  @media (max-width: 1440px) {
    .container {
      .checkout-container {
        .checkout-content {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .container {
      .checkout-container {
        .checkout-content {
          flex-direction: column-reverse;
          margin-top: 1rem;

          .summary-card {
            max-width: unset;
            margin-left: unset;
          }
        }
      }
    }
  }

  @media (max-width: 520px) {
    .container {
      font-size: 14px;

      .checkout-container {
        .checkout-content {
          justify-content: unset;

          .forms-container {
            .checkout {
              p {
                font-size: 14px;
              }

              .payment-button-container {
                justify-content: center;
                align-items: center;
                margin-left: unset;
                display: flex;

                button {
                  margin-left: unset;
                }
              }
            }
          }
        }
      }
    }
  }
</style>