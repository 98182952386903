<template>
  <div class="summary-container">
    <div class="summary" @click="toggleState">
      <div class="card">
        <div class="header">
          <div class="dollar-icon-container">
            <i class="icon-dollar-sign" />
          </div>

          <h1>
            Resumo
          </h1>

          <div v-if="isMobile" class="arrow-icon-container">
            <i v-if="hideCard" class="icon-arrow-up"/>
            <i v-else class="icon-arrow-down"/>
          </div>
        </div>

        <div v-if="!hideCard">
          <div class="items-container">
            <div v-for="(item, index) in items" :key="index" class="service-container">
              <div class="service-content">
                <div class="service">
                  {{ item.quantity }} x {{ item.description }}
                </div>

                <div class="price">
                  {{ item.price }}
                </div>
              </div>
            </div>
          </div>

          <div class="line-break"></div>

          <div class="payment-container">
            <div v-if="hasRateOptions">
              <div v-for="(item, index) in options" :key="index" class="option">
                <p class="label">
                  {{ item.label }}
                </p>

                <p class="amount">
                  {{ item.value }}
                </p>
              </div>
            </div>

            <div class="payment">
              Total
            </div>

            <div class="payment number">
              {{ grandValueReal }}
            </div>

            <div v-if="hasInstallments" class="installments-container">
              <div class="installments">
                Forma de pagamento

                <span>
                  {{ textOfInstallments }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'SummaryCardMobile',

    data() {
      return {
        hideCard: false,
        hasInstallments: false,
        grandValue: '',
        grandValueReal: '',
        textOfInstallments: '',
        items: [],
        options: [],
      };
    },

    props: {
      data: {
        type: Object,
        required: true,
      },

      isMobile: {
        type: Boolean,
        default: false
      },

      hasRateOptions: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      ...mapState({
        paymentData: state => state.data
      }),
    },

    created() {
      if (this.isMobile) {
        this.hideCard = true;
      }
    },

    mounted() {
      this.grandValue = this.data.grand_total;
      this.grandValueReal = this.$utils.formatBrlAmount(this.grandValue);

      if (this.data.transaction != null && this.$utils.toUpperCase(this.data.transaction.status) == 'SUCCEEDED') {
        if (this.data.transaction.payment.credit_card) {
          this.paymentData.installments.numberOfInstallments = this.data.transaction.payment.credit_card.installments.count;
        } else {
          this.paymentData.installments.numberOfInstallments = 1;
        }
      }

      this.setOptionPayment('Vencimento', this.$utils.dateFormat(this.data.due_date));
      this.setOptionPayment('Desconto', this.$utils.formatBrlAmount(this.data.on_time_payment_discount_amount));
      this.setOptionPayment('Multa', this.$utils.formatBrlAmount(this.data.late_fee_amount));
      this.setOptionPayment('Juros', this.$utils.formatBrlAmount(this.data.daily_interest_amount));

      if (!this.$utils.isUndefined(this.data.items)) {
        this.data.items.forEach(item => {
          this.items.push({
            quantity: item.quantity,
            description: item.description,
            price: this.$utils.formatBrlAmount(parseFloat(item.price))
          });
        });
      }
    },

    watch: {
      'paymentData.installments.numberOfInstallments'(value) {
        this.hasInstallments = !!value;
        this.textOfInstallments = value + 'x de ' + this.$utils.formatBrlAmount(parseFloat(this.installmentAmount(this.grandValue, value)));
      }
    },

    methods: {
      toggleState() {
        if (this.isMobile) {
          this.hideCard = !this.hideCard;
        }
      },

      installmentAmount(amount, installments) {
        return amount / installments;
      },

      setOptionPayment(label, value) {
        this.options.push({
          label: label,
          value: value
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .summary-container {
    .summary {
      background: #efefef;
      border-radius: 1rem;
      padding: 1.5rem;
      margin-bottom: 1.5rem;

      .card {
        font-size: 20px;
        font-weight: 400;

        .header {
          display: flex;
          align-items: center;

          .dollar-icon-container {
            margin-right: 0.5rem;
            justify-content: center;
            display: flex;

            i {
              color: #B9B9B9;
              font-size: 1.5rem;
            }
          }

          h1 {
            font-size: 20px;
            font-weight: 600;
          }

          .arrow-icon-container {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            cursor: pointer;

            i {
              font-size: 12px;
            }
          }
        }

        .items-container {
          max-height: 300px;
          overflow: auto;
          margin: 1rem 0 1rem 0;

          .service-container {
            margin-bottom: 0.5rem;

            &:last-child {
              margin-bottom: 0;
            }

            .service-content {
              .service {
                word-wrap: break-word;
                font-size: 18px;
              }

              .price {
                margin: 0 5px 0 5px;
                margin-top: 0;
                font-size: 16px;
              }
            }
          }
        }

        .line-break {
          border-bottom: 1px solid #b9b9b9;
        }

        .option {
          display: flex;
          margin-bottom: 0.2rem;

          p {
            font-size: 16px;
          }

          .amount {
            margin-left: auto;
          }
        }

        .payment-container {
          margin-top: 1rem;

          .payment {
            display: inline;
            font-weight: 600;
            font-size: 20px;

            &.number {
              position: relative;
              float: right;
            }
          }

          .installments-container {
            display: flex;
            justify-content: flex-end;

            .installments {
              color: #6d6f6f;
              margin-top: 1rem;
              font-size: 16px;
              font-weight: 400;

              span {
                font-size: 20px;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 950px) {
    .summary-container {
      .summary {
        .card {
          font-size: 16px;

          .header {
            .dollar-icon-container {
              i {
                font-size: 1.5rem;
              }
            }

            h1 {
              font-size: 20px;
            }

            .arrow-icon-container {
              i {
                font-size: 10px;
              }
            }
          }

          .payment-container {
            .payment {
              font-size: 16px;
            }

            .installments-container {
              .installments {
                font-size: 16px;

                span {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 520px) {
    .summary-container {
      .summary {
        padding: 1rem;

        .card {
          font-size: 14px;

          .header {
            .dollar-icon-container {
              i {
                font-size: 1.4rem;
              }
            }

            h1 {
              font-size: 16px;
            }
          }

          .items-container {
            .service-container {
              .service-content {
                .service {
                  font-size: 16px;
                }

                .price {
                  font-size: 14px;
                }
              }
            }
          }

          .payment-container {
            .option {
              p {
                font-size: 12px;
              }
            }

            .payment {
              font-size: 14px;
            }

            .installments-container {
              .installments {
                font-size: 14px;

                span {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
</style>