<template>
  <div class="default-payment-card-container">
    <default-section title="Meio de pagamento">
      <template v-slot:content>
        <div class="content">
          <div class="value-output">
            Valor total

            <div class="value">
              {{ parsedAmount }}
            </div>
          </div>
        </div>
      </template>
    </default-section>
  </div>
</template>

<script>
  import DefaultSection from '@/components/base/DefaultSection';

  export default {
    data() {
      return {
        parsedAmount: ''
      };
    },

    components: {
      'default-section': DefaultSection
    },

    props: {
      amount: {
        type: Number,
        default: 0
      }
    },

    watch: {
      amount(value) {
        this.parsedAmount = this.parseAmount(value);
      }
    },

    mounted() {
      this.parsedAmount = this.parseAmount(this.amount);
    },

    methods: {
      parseAmount(value) {
        return this.$utils.formatBrlAmount(value);
      }
    }
  };
</script>

<style lang="scss">
  .default-payment-card-container {
    .content {
      margin-top: 1.5rem;
      display: flex;

      .value-output {
        font-size: 14px;
        width: 100%;

        .value {
          padding: 1rem;
          max-width: 12rem;
          width: 100%;
          height: 48px;
          background: #dadada;
          border-radius: 0.5rem;
          margin-top: 0.5rem;
          font-weight: 600;
          font-size: 20px;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  @media (max-width: 520px) {
    .default-payment-card-container {
      .content {
        .value-output {
          font-size: 12px;
        }
      }
    }
  }
</style>