<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'App',

    metaInfo() {
      return {
        link: [
          {
            rel: 'icon',
            href: this.getFaviconPath
          }
        ]
      };
    },

    computed: {
      ...mapGetters([
        'getFaviconPath'
      ])
    },
  };
</script>

<style lang="scss">
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    letter-spacing: 0.2px;
    box-sizing: border-box;
  }

  body {
    min-width: 320px;
    min-height: 100vh;
    padding: 0;
    overflow-x: hidden;
  }

  body, input, select, button {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    letter-spacing: 0.5px;
    font-style: normal;
  }

  h1 {
    font-size: 32px;
    font-weight: 600;
  }

  img {
    -webkit-user-drag: none;
  }

  h2 {
    font-size: 24px;
    font-weight: 600;
  }

  h3 {
    font-size: 16px;
    font-weight: 600;
  }

  h4 {
    font-size: 14px;
    font-weight: 600;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    user-select: none;
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: #8E8E8E #EFEFEF;
  }

  *::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  *::-webkit-scrollbar-track {
    background: #EFEFEF;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #8E8E8E;
    height: 35px;
    width: 10px;
  }

  #app {
    min-height: 100vh;
  }
</style>
