<template>
  <div class="header-container" :style="styleObject">
    <div class="header">
      <h1 class="text">
        {{ data.text }}
      </h1>

      <h1 class="description">
        <div v-if="data.description.label" class="label">
          {{ data.description.label }}
        </div>

        <span>
          {{ data.description.value }}
        </span>
      </h1>
    </div>

    <div class="line-break"></div>
  </div>
</template>

<script>
  export default {
    name: 'CustomHeader',

    props: {
      data: {
        type: Object,
        default() {
          return {
            text: 'FirstName LastName',
            description: {
              label: 'Contrato',
              value: '000000'
            },
            lineColor: '#DADADA',
            textColor: '#6D6F6F',
            descriptionColor: '#6D6F6F',
          };
        }
      }
    },

    computed: {
      styleObject: function () {
        return {
          '--border-color': this.data.lineColor,
          '--text-color': this.data.textColor,
          '--description-color': this.data.descriptionColor,
        };
      }
    }
  };
</script>

<style lang="scss" scoped>
  .header-container {
    .header {
      padding: 0 2rem;
      display: flex;

      h1 {
        font-size: 20px;

        span {
          font-weight: 600;
        }

        &.text {
          color: var(--text-color);
          text-transform: capitalize
        }

        &.description {
          color: var(--description-color);
          font-weight: 400;
          position: relative;
          margin-left: auto;
          display: flex;

          .label {
            margin-right: 0.5rem;
          }
        }
      }
    }

    .line-break {
      border-bottom: 2px solid var(--border-color);
      margin-top: 0.5rem;
      margin-bottom: 3rem;
    }
  }

  @media (max-width: 1200px) {
    .header-container {
      .header {
        h1 {
          font-size: 18px;
        }
      }

      .line-break {
        margin-bottom: 2rem;
      }
    }
  }

  @media (max-width: 768px) {
    .header-container {
      .header {
        padding: 0;
      }
    }
  }

  @media (max-width: 520px) {
    .header-container {
      .header {
        display: flex;
        flex-direction: column;

        h1 {
          font-size: 16px;

          &.description {
            margin-left: unset;
          }
        }
      }

      .line-break {
        margin-bottom: 2rem;
      }
    }
  }
</style>
