<template>
  <div class="summary-container">
    <div class="summary" @click="toggleState">
      <div class="card">
        <div class="header">
          <div class="dollar-icon-container">
            <i class="icon-dollar-sign" />
          </div>

          <h1>
            Resumo
          </h1>

          <div v-if="isMobile" class="arrow-icon-container">
            <i v-if="showDetails" class="icon-arrow-up"/>
            <i v-else class="icon-arrow-down"/>
          </div>
        </div>

        <div v-if="!showDetails">
          <div class="items-container">
            <div v-for="(item, index) in items" :key="index" class="service-container">
              <div class="service-content">
                <div class="service">
                  {{ item.description }}
                </div>

                <div class="price">
                  {{ item.value }}
                </div>
              </div>
            </div>
          </div>

          <div v-if="items.length > 0" class="line-break"></div>

          <div class="payment-container">
            <div v-if="rateItems.length > 0">
              <div v-for="(item, index) in rateItems" :key="index" class="option">
                <p class="label">
                  {{ item.label }}
                </p>

                <p class="amount">
                  {{ item.value }}
                </p>
              </div>
            </div>

            <div class="payment">
              Total
            </div>

            <div class="payment number">
              {{ parsedAmount }}
            </div>

            <div v-if="textOfInstallments" class="installments-container">
              <div class="installments">
                Forma de pagamento

                <span>
                  {{ textOfInstallments }}
                </span>
              </div>
            </div>

            <p v-if="observation" class="observation">
              {{ observation }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SummaryCard',

    data() {
      return {
        showDetails: false,
        isMobile: false,
        parsedAmount: ''
      };
    },

    props: {
      amount: {
        type: Number,
        default: 0
      },

      observation: {
        type: String,
        default: ''
      },

      textOfInstallments: {
        type: String,
        default: ''
      },

      items: {
        type: Array,
        default: () => []
      },

      rateItems: {
        type: Array,
        default: () => []
      }
    },

    mounted() {
      this.onResize();

      window.addEventListener('resize', () => {
        this.onResize();
      });

      if (this.isMobile) {
        this.showDetails = true;
      }

      this.parsedAmount = this.parseAmount(this.amount);
    },

    watch: {
      amount(value) {
        this.parsedAmount = this.parseAmount(value);
      }
    },

    methods: {
      onResize() {
        this.isMobile = window.innerWidth <= 768;
      },

      toggleState() {
        if (this.isMobile) {
          this.showDetails = !this.showDetails;
        }
      },

      parseAmount(value) {
        return this.$utils.formatBrlAmount(value);
      }
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
    }
  };
</script>

<style lang="scss" scoped>
  .summary-container {
    .summary {
      background: #efefef;
      border-radius: 1rem;
      padding: 1.5rem;
      margin-bottom: 1.5rem;

      .card {
        font-size: 20px;
        font-weight: 400;

        .header {
          display: flex;
          align-items: center;

          .dollar-icon-container {
            margin-right: 0.5rem;
            justify-content: center;
            display: flex;

            i {
              color: #B9B9B9;
              font-size: 1.5rem;
            }
          }

          h1 {
            font-size: 20px;
            font-weight: 600;
          }

          .arrow-icon-container {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            cursor: pointer;

            i {
              font-size: 12px;
            }
          }
        }

        .items-container {
          max-height: 300px;
          overflow: auto;
          margin: 1rem 0 1rem 0;

          .service-container {
            margin-bottom: 0.5rem;

            &:last-child {
              margin-bottom: 0;
            }

            .service-content {
              .service {
                word-wrap: break-word;
                font-size: 18px;
              }

              .price {
                margin: 0 5px 0 5px;
                margin-top: 0;
                font-size: 16px;
              }
            }
          }
        }

        .line-break {
          border-bottom: 1px solid #b9b9b9;
        }

        .option {
          display: flex;
          margin-bottom: 0.2rem;

          p {
            font-size: 14px;
          }

          .amount {
            margin-left: auto;
          }
        }

        .payment-container {
          margin-top: 1rem;

          .payment {
            display: inline;
            font-weight: 600;
            font-size: 20px;

            &.number {
              position: relative;
              float: right;
            }
          }

          .installments-container {
            display: flex;
            justify-content: flex-end;

            .installments {
              color: #6d6f6f;
              margin-top: 1rem;
              font-size: 14px;
              font-weight: 400;

              span {
                font-size: 16px;
                font-weight: 600;
              }
            }
          }

          .observation {
            margin-top: 0.5rem;
            color: #8E8E8E;
            text-align: right;
          }
        }
      }
    }
  }

  @media (max-width: 950px) {
    .summary-container {
      .summary {
        .card {
          font-size: 16px;

          .header {
            .dollar-icon-container {
              i {
                font-size: 1.5rem;
              }
            }

            h1 {
              font-size: 20px;
            }

            .arrow-icon-container {
              i {
                font-size: 10px;
              }
            }
          }

          .payment-container {
            .payment {
              font-size: 16px;
            }

            .installments-container {
              .installments {
                font-size: 16px;

                span {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 520px) {
    .summary-container {
      .summary {
        padding: 1.5rem 1rem;

        .card {
          font-size: 14px;

          .header {
            .dollar-icon-container {
              i {
                font-size: 1.4rem;
              }
            }

            h1 {
              font-size: 16px;
            }
          }

          .items-container {
            .service-container {
              .service-content {
                .service {
                  font-size: 16px;
                }

                .price {
                  font-size: 14px;
                }
              }
            }
          }

          .payment-container {
            .option {
              p {
                font-size: 12px;
              }
            }

            .payment {
              font-size: 14px;
            }

            .installments-container {
              .installments {
                font-size: 14px;

                span {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
</style>