<template>
  <div class="pix-card-container">
    <default-section title="Meio de pagamento - Pix">
      <template v-slot:content>
        <div v-if="emv" class="content has-qr-code">
          <div class="instructions-container">
            <div class="title">
              Como realizar o pagamento:
            </div>

            <div class="step-container">
              <div v-for="(item, index) in steps" :key="index" class="step">
                <div class="number-container">
                  <div class="number">
                    {{ item.step }}
                  </div>
                </div>

                <div class="text">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>

          <div class="qr-code-card-container">
            <qr-code-card-component
              :emvValue="emv"
              :hasQrCodeRequest="hasRequest"
              :isQrCodeExpired="isQrCodeExpired"
              @refresh="refresh"
            />
          </div>
        </div>

        <div v-else class="content">
          <div class="button-container">
            <button-component
              class="button"
              text="Gerar QR Code"
              :hasRequest="hasRequest"
              :disableButton="hasRequest"
              @click.native="createPayment"
            />
          </div>
        </div>
      </template>
    </default-section>
  </div>
</template>

<script>
  import DefaultSection from '@/components/base/DefaultSection';
  import Button from '@/components/Button';
  import QrCodeCard from '@/components/ecommerce/QrCodeCard';

  export default {
    name: 'PixPaymentForm',

    data() {
      return {
        steps: [
          {
            text: 'Abra o aplicativo do seu banco ou instituição finaceira e selecione Pix',
            step: 1
          },
          {
            text: 'Escaneie o QR Code ou a use a opção copia e cola',
            step: 2
          },
          {
            text: 'Cofirme as informações e finalize o pagamento',
            step: 3
          }
        ]
      };
    },

    props: {
      emv: {
        type: String,
        default: ''
      },

      hasRequest: {
        type: Boolean,
        default: false
      },

      isQrCodeExpired: {
        type: Boolean,
        default: false
      }
    },

    components: {
      'default-section': DefaultSection,
      'button-component': Button,
      'qr-code-card-component': QrCodeCard
    },

    methods: {
      createPayment() {
        this.$emit('createPayment');
      },

      refresh() {
        this.$emit('refresh');
      }
    },
  };
</script>

<style lang="scss" scoped>
  .pix-card-container {
    width: 100%;
    min-width: 385px;

    .content {
      margin-top: 1.5rem;
      display: flex;

      &.has-qr-code {
        .instructions-container {
          font-size: 14px;

          .title {
            font-weight: 500;
            font-size: 16px;
          }

          .step-container {
            margin-top: 0.5rem;
            padding: 1rem;

            .step {
              display: flex;
              margin-bottom: 1rem;

              .number-container {
                .number {
                  color: #FFFFFF;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-weight: 600;
                  height: 25px;
                  width: 25px;
                  background: #ff9c00;
                  border-radius: 100%;
                }
              }

              .text {
                margin-left: 0.5rem;
              }
            }
          }
        }

        .qr-code-card-container {
          margin-left: 3rem;
          align-items: center;
          justify-content: center;
          display: flex;
        }

        .logo-pix {
          margin-top: 2rem;
          justify-content: center;
          align-items: center;
          display: flex;

          img {
            width: 145px;
          }
        }
      }

      .button-container {
        display: flex;
        align-items: flex-end;
        width: 15rem;

        .button {
          border-radius: 0.5rem;
          width: 100%;
        }
      }

      .pix-card-container {
        display: flex;
        justify-content: center;
      }
    }
  }

  @media (max-width: 1215px) {
    .pix-card-container {
      .content {
        flex-direction: column;

          &.has-qr-code {
            .qr-code-card-container {
              margin-left: unset;
              margin-top: 0.5rem;
            }
          }

        .button-container {
          margin-top: 1rem;
        }
      }
    }
  }

  @media (max-width: 520px) {
    .pix-card-container {
      min-width: unset;

      .content {
        margin-top: 0.5rem;

        &.has-qr-code {
          .instructions-container {
            font-size: 12px;

            .title {
              font-size: 14px;
            }

            .step-container {
              .step {
                .number-container {
                  .number {
                    height: 20px;
                    width: 20px;
                  }
                }
              }
            }
          }
        }

        .value-output {
          margin-right: unset;
          font-size: 12px;

          .value {
            font-size: 16px;
            width: 100%;
          }
        }

        .button-container {
          width: 100%;
        }
      }
    }
  }
</style>