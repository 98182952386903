import axios from 'axios';

export const instance = (data) => {
  const url = data?.forceProdUrl && process.env.NODE_ENV == 'development' ?
    process.env.VUE_APP_BASE_PROD_API_URL :
    process.env.VUE_APP_BASE_API_URL;

  const instance = axios.create({
    baseURL: url,
    timeout: 10000,
    headers: {
      'Content-Type': 'application/json',
    }
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error)
  );

  return instance;
};

export default instance;