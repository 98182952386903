<template>
  <div class="user-data-container">
    <default-section title="Insira os seus dados">
      <template v-slot:content>
        <ValidationObserver
          tag="div"
          ref="form"
          class="content"
        >
          <form @input="validateForm()">
            <input-field
              label="E-mail"
              placeholder="Ex. exemplo@mail.com"
              rules="required|mail"
              styles="large"
              type="text"
              :disable="isBlocked"
              v-model="data.email"
            />

            <p>
              Endereço
            </p>

            <input-field
              label="CEP"
              placeholder="Ex. 88888-888"
              mask="#####-###"
              rules="required|postalCode"
              styles="small"
              :maxLength="9"
              type="text"
              refName="postalCode"
              :disable="isBlocked"
              @blur="postalCodeRequest"
              v-model="data.postalCode"
            />

            <div class="two-fields">
              <input-field
                label="Endereço"
                placeholder="Ex. Avenida São João"
                rules="required"
                styles="large"
                class="field"
                type="text"
                refName="address"
                :disable="isBlocked || !hasPostalCode"
                v-model="data.address"
              />

              <input-field
                label="Número"
                placeholder="Ex. 1500"
                rules="required"
                styles="small"
                class="field"
                type="number"
                refName="number"
                :disable="isBlocked || !hasPostalCode"
                v-model="data.number"
              />
            </div>

            <div class="two-fields">
              <input-field
                label="Cidade"
                placeholder="Ex. Centro"
                rules="required"
                styles="large"
                class="field"
                type="text"
                refName="city"
                :disable="isBlocked || !hasPostalCode"
                v-model="data.city"
              />

              <input-field
                label="Estado"
                placeholder="Ex. SC"
                rules="required|min:2"
                :maxLength="2"
                mask="AA"
                styles="small"
                class="field"
                type="text"
                refName="state"
                :disable="isBlocked || !hasPostalCode"
                v-model="data.state"
              />
            </div>


            <div class="two-fields">
              <input-field
                label="Bairro"
                placeholder="Ex. Centro"
                rules="required"
                styles="large"
                class="field"
                type="text"
                refName="neighborhood"
                :disable="isBlocked || !hasPostalCode"
                v-model="data.neighborhood"
              />

              <input-field
                label="Complemento"
                placeholder="Ex. Bloco B - Apartamento 500"
                styles="large"
                class="field"
                type="text"
                :disable="isBlocked || !hasPostalCode"
                v-model="data.complement"
              />
            </div>
          </form>
        </ValidationObserver>
      </template>
    </default-section>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import DefaultSection from '@/components/base/DefaultSection';
  import InputField from '@/components/InputField';
  import { ValidationObserver } from 'vee-validate';
  import Validation from '@/services/validations';
  import '@/services/rules';

  export default {
    name: 'UserDataCard',

    data() {
      return {
        hasPostalCode: false,
        hasPostalCodeRequest: false
      };
    },

    props: {
      isBlocked: {
        type: Boolean,
        default: true,
        required: true,
      }
    },

    components: {
      ValidationObserver,
      'default-section': DefaultSection,
      'input-field': InputField
    },

    computed: {
      ...mapState({
        data: state => state.data,
        formStepStatus: state => state.formStepStatus
      }),
    },

    watch: {
      'data.postalCode'(value) {
        if (!this.$utils.isNull(value) && this.$utils.removeCharacters(value).length === 8) {
          this.postalCodeRequest();
        } else {
          this.hasPostalCode = false;
          this.$refs.form.reset();
          this.resetAddressFields();
        }
      },

      'data.neighborhood'(value) {
        if (value != null && value.length > 0) {
          this.$refs.form.refs.neighborhood.reset();
        }
      },

      'data.address'(value) {
        if (value != null && value.length > 0) {
          this.$refs.form.refs.address.reset();
        }
      },

      'data.number'(value) {
        if (value != null && value.length > 0) {
          this.$refs.form.refs.number.reset();
        }
      },

      'data.city'(value) {
        if (value != null && value.length > 0) {
          this.$refs.form.refs.city.reset();
        }
      },

      'data.state'(value) {
        if (value != null && value.length > 0) {
          this.$refs.form.refs.state.reset();
        }
      }
    },

    methods: {
      validateForm() {
        if (this.passedForm() && Validation.isEmail(this.data.email) && Validation.isPostalCode(this.data.postalCode)) {
          this.formStepStatus.thirdStep = 'check';
          this.formStepStatus.fourthStep = 'wait';
          this.formStepStatus.disablePaymentMethodForm = false;
          this.formStepStatus.disableButton = true;
        } else {
          this.formStepStatus.thirdStep = 'wait';
          this.formStepStatus.fourthStep = 'default';
          this.formStepStatus.disablePaymentMethodForm = true;
          this.$store.commit('resetPaymentMethodData');
          this.formStepStatus.disableButton = true;
        }
      },

      passedForm() {
        return !(!this.data.email || !this.data.postalCode || !this.data.address || !this.data.number || !this.data.neighborhood || !this.data.city || !(this.data.state.length == 2));
      },

      postalCodeRequest() {
        if (!this.hasPostalCodeRequest && this.data.postalCode != null && !this.hasPostalCode && this.$utils.removeCharacters(this.data.postalCode)) {
          this.hasPostalCodeRequest = true;

          this.$store.dispatch('getPostalCode', this.$utils.removeCharacters(this.data.postalCode))
            .then(res => {
              this.data.address = res.data.address_line_1;
              this.data.number = res.data.address_line_2;
              this.data.neighborhood = res.data.neighborhood;
              this.data.city = res.data.city;
              this.data.state = res.data.state_code;

              this.$refs.form.validate();
            })
            .catch(() => {
              this.resetAddressFields();

              this.$refs.form.setErrors({
                postalCode: 'Não foi possivel carregar o endereço',
              });
            })
            .finally(() => {
              this.hasPostalCodeRequest = false;
              this.hasPostalCode = true;
            });
        }
      },

      resetAddressFields() {
        this.data.address = null;
        this.data.number = null;
        this.data.neighborhood = null;
        this.data.city = null;
        this.data.state = null;
      }
    },
  };
</script>

<style lang="scss" scoped>
  .user-data-container {
    .content {
      margin-top: 1.5rem;

      form {
        p {
          color: #6D6F6F;
          margin-top: 1rem;
          margin-bottom: 1rem;
        }

        .two-fields {
          display: flex;
          margin-top: 1rem;

          .field {
            &:last-child {
              margin-left: 1rem;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1280px) {
    .user-data-container {
      .content {
        form {
          .two-fields {
            flex-direction: column;
            margin-top: unset;

            .field {
              margin-top: 1rem;

              &:last-child {
                margin-left: unset;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 520px) {
    .user-data-container {
      .content {
        form {
          p {
            font-size: 12px;
          }
        }
      }
    }
  }
</style>