import { instance } from '@/config';

const state = {
  checkoutId: '',
  sellerId: '',

  totalAmount: 0,
  totalOriginalAmount: 0,
  totalFee: 0,
};

const actions = {
  getCheckout({ state }) {
    return new Promise((resolve, reject) => {
      instance().get(`/checkouts/${state.checkoutId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getSellerSalesPlan({ state }) {
    return new Promise((resolve, reject) => {
      instance({ forceProdUrl: true }).get(`/sellers/${state.sellerId}/sales-plan`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  pay({ state }, data) {
    return new Promise((resolve, reject) => {
      instance().post(`/checkouts/${state.checkoutId}/pay`, data)
        .then(res => {
          resolve(res.data);
        }).catch(err => {
          reject(err.response);
        });
    });
  },
};

const mutations = {
  setCheckoutId(state, id) {
    state.checkoutId = id;
  },

  setSellerId(state, id) {
    state.sellerId = id;
  },
};

const getters = {
  getCheckoutId(state) {
    return state.checkoutId;
  },

  getSellerId(state) {
    return state.sellerId;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};