<template>
  <div class="payment-method-card">
    <default-section title="Meio de pagamento - Cartão de crédito">
      <template v-slot:content>
        <div class="content">
          <ValidationObserver tag="form" ref="form" @input="validateForm()" @change="validateForm()">
            <div class="tree-fields">
              <input-field
                class="field"
                label="Número do cartão"
                placeholder="Ex. 5381579886310193"
                rules="required|cardNumber"
                styles="large"
                type="number"
                :hasCardBrand="true"
                :maxLength="17"
                :disable="isDisable || hasRequest"
                v-model="form.cardNumber"
              />

              <div class="two-fields">
                <input-field
                  class="field"
                  label="Data de validade"
                  placeholder="Ex. 05/20"
                  mask="##/##"
                  rules="required|validExpirationCard"
                  styles="small"
                  type="text"
                  :disable="isDisable"
                  v-model="form.expirationDate"
                />

                <input-field
                  class="field"
                  label="Cód. de segurança"
                  placeholder="Ex. 452"
                  :rules="`required|max:${maxSecurityCodeLength}|min:${maxSecurityCodeLength}`"
                  styles="small"
                  type="number"
                  :disable="isDisable"
                  v-model="form.securityCode"
                />
              </div>
            </div>

            <div class="two-fields">
              <input-field
                class="field"
                label="Nome do titular do cartão"
                placeholder="Ex. Maria Ferreira Dias"
                rules="required|validCompletedName"
                styles="large"
                type="text"
                :disable="isDisable"
                v-model="form.fullHolderName"
              />

              <select-input-field
                class="field"
                label="Número de parcelas"
                rules="required"
                styles="large"
                :disable="isDisable || isDisableInstalments || installmentOptions.length === 0"
                :options="installmentOptions"
                v-model="form.installments"
              />
            </div>
          </ValidationObserver>
        </div>
      </template>
    </default-section>
  </div>
</template>

<script>
  import Validation from '@/services/validations';
  import DefaultSection from '@/components/base/DefaultSection';
  import InputField from '@/components/base/InputField';
  import SelectInputField from '@/components/base/SelectInputField';
  import { ValidationObserver } from 'vee-validate';

  export default {
    name: 'CreditPaymentForm',

    data() {
      return {
        hasRequest: false,

        isDisableInstalments: true,
        installmentOptions: [],

        maxSecurityCodeLength: 3,

        cardBrand: '',
        creditOnlineSalesPlan: null,

        cardNumberTimer: 0,

        initialAmount: 0,

        form: {
          cardNumber: '',
          expirationDate: '',
          securityCode: '',
          fullHolderName: '',
          installments: '',
        },

        paymentDetails: {
          amount: 0,
          originalAmount: 0,
          amountByInstallment: 0,
          feeCoverageAmount: 0,
          numberOfInstallments: 1,
        }
      };
    },

    props: {
      isDisable: {
        type: Boolean,
        default: false,
      },

      amount: {
        type: Number,
        required: true,
      },

      minInstallmentAmount: {
        type: Number,
        default: 25,
      },

      maxInstallmentQuantity: {
        type: Number,
        default: 12,
      },

      initialInstallmentFeeCoverage: {
        type: Number,
        default: 1,
      },

      isCustomerFeeCoverage: {
        type: Boolean,
        default: false,
      },
    },

    components: {
      ValidationObserver,
      'default-section': DefaultSection,
      'input-field': InputField,
      'select-input-field': SelectInputField,
    },

    watch: {
      'form.cardNumber'(value) {
        clearTimeout(this.cardNumberTimer);

        const cardBrand = this.$utils.getCardBrand(value);
        if (!cardBrand) {
          this.cardBrand = '';
          this.installmentOptions = [];
          this.isDisableInstalments = true;
          this.maxSecurityCodeLength = 3;
          return;
        }

        this.cardBrand = cardBrand.fullName;
        this.maxSecurityCodeLength = cardBrand.codeLength;

        if (this.cardBrand && value.length >= 14) {
          this.cardNumberTimer = setTimeout(() => {
            this.calculateInstallmentsFee();
          }, 950);
        }
      },

      'form.installments'(value) {
        let originalAmount = this.initialAmount;
        let amount = this.initialAmount;
        let amountByInstallment = amount / value;
        let feeCoverageAmount = 0;

        const hasFeeCoverage = value > this.initialInstallmentFeeCoverage;
        if (hasFeeCoverage) {
          const fees = this.getFeeByCardBrand(this.cardBrand);
          if (fees) {
            const feeData = fees.find(fee => fee.numberInstallments === value);
            if (feeData) {
              amount = this.calculateFee(amount, feeData.percentAmount);
              amountByInstallment = amount / value;
              feeCoverageAmount = amount - originalAmount;
            }
          }
        }

        this.paymentDetails.originalAmount = originalAmount;
        this.paymentDetails.amount = amount;
        this.paymentDetails.amountByInstallment = amountByInstallment;
        this.paymentDetails.feeCoverageAmount = feeCoverageAmount;
        this.paymentDetails.numberOfInstallments = value;

        this.$emit('paymentDetails', this.paymentDetails);
      },
    },

    mounted() {
      this.initialAmount = this.amount;
    },

    methods: {
      validateForm() {
        const valid = this.passedRequiredFields() && this.passedFields();

        this.$emit('isValid', valid);

        if (valid) {
          this.$emit('form', this.form);
        }
      },

      passedFields() {
        return Validation.isDate(this.form.expirationDate) && Validation.isValidFullName(this.form.fullHolderName)
          && this.form.securityCode.length === this.maxSecurityCodeLength;
      },

      passedRequiredFields() {
        return !!this.form.cardNumber && !!this.form.expirationDate && !!this.form.securityCode
          && !!this.form.fullHolderName && !!this.form.installments;
      },

      async calculateInstallmentsFee() {
        this.installmentOptions = [];

        if (!this.cardBrand) return;

        if (this.amount <= this.minInstallmentAmount) {
          this.installmentOptions.push({
            label: `1x de ${this.$utils.formatBrlAmount(this.amount)}`,
            value: 1
          });
        }

        if (this.isCustomerFeeCoverage && !this.creditOnlineSalesPlan) {
          await this.fetchSellerSalesPlan();
        }

        const fees = this.getFeeByCardBrand(this.cardBrand);
        if (fees) {
          fees.forEach(fee => {
            const hasFeeCoverage = this.initialInstallmentFeeCoverage && fee.numberInstallments > this.initialInstallmentFeeCoverage;
            const amount = hasFeeCoverage ? this.calculateFee(this.amount, fee.percentAmount) : this.amount;
            const installmentAmount = amount / fee.numberInstallments;

            if (installmentAmount >= this.minInstallmentAmount && fee.numberInstallments <= this.maxInstallmentQuantity) {
              this.installmentOptions.push({
                label: `${fee.numberInstallments}x de ${this.$utils.formatBrlAmount(installmentAmount)} (${!hasFeeCoverage ? 'sem juros' : 'total: ' + this.$utils.formatBrlAmount(amount)})`,
                value: fee.numberInstallments
              });
            }
          });
        } else {
          for (var i = 1; i <= this.maxInstallmentQuantity; i++) {
            const installmentAmount = this.amount / i;

            if (installmentAmount >= this.minInstallmentAmount) {
              this.installmentOptions.push({
                label: `${i}x de ${this.$utils.formatBrlAmount(installmentAmount)} (sem juros)`,
                value: i
              });
            }
          }
        }

        this.isDisableInstalments = false;
      },

      getFeeByCardBrand(cardBrand) {
        if (this.creditOnlineSalesPlan && this.creditOnlineSalesPlan[cardBrand]) {
          return this.creditOnlineSalesPlan[cardBrand];
        }

        return null;
      },

      async fetchSellerSalesPlan() {
        this.hasRequest = true;

        await this.$store.dispatch('ecommerceModule/getSellerSalesPlan')
          .then(res => {
            const { credit } = res.data;

            const salesPlan = {};

            for (let item in credit) {
              salesPlan[item] = credit[item].map(item => {
                const { number_installments, percent_amount } = item.online;

                return {
                  numberInstallments: number_installments,
                  percentAmount: percent_amount,
                };
              });
            }

            this.creditOnlineSalesPlan = salesPlan;
          })
          .catch(err => {
            console.log(err);
          });

        this.hasRequest = false;
      },

      calculateFee(amount, fee) {
        return amount / (1 - fee / 10000);
      },
    }
  };
</script>

<style lang="scss" scoped>
  .payment-method-card {
    width: 100%;
    min-width: 385px;

    .content {
      margin-top: 1.5rem;

      .field {
        margin-right: 1rem;
      }

      .two-fields {
        display: flex;
      }

      .tree-fields {
        display: flex;
        margin-bottom: 1rem;
      }
    }
  }

  @media (max-width: 1280px) {
    .payment-method-card {
      .content {
        .two-fields {
          margin-top: 1rem;

          .field:last-child {
            margin-right: 0;
          }
        }

        .tree-fields {
          flex-direction: column;
        }
      }
    }
  }

  @media (max-width: 1050px) {
    .payment-method-card {
      .content {
        .two-fields {
          flex-direction: column;

          .field:last-child {
            margin-top: 1rem;
          }
        }
      }
    }
  }

  @media (max-width: 520px) {
    .payment-method-card {
      min-width: unset;

      .content {
        margin-top: 0.5rem;
      }
    }
  }
</style>