import Vue from 'vue';
import Vuex from 'vuex';

import { instance } from '../config/index';

import ecommerceModule from './modules/ecommerce';

Vue.use(Vuex);

const state = {
  hasSpinner: true,
  hasError: false,
  checkoutId: '',
  faviconPath: '/favicon.ico',

  formStepStatus: {
    disableButton: true,
    disableToPayForm: false,
    disableUserDataForm: true,
    disablePaymentMethodForm: true,
    firstStep: 'wait',
    secondStep: 'default',
    thirdStep: 'default',
    fourthStep: 'default'
  },

  checkoutStatus: {
    isExpired: false,
    isPayment: true,
    isSuccessPayment: true,
    isPixPaymentError: false
  },

  data: {
    email: null,
    postalCode: null,
    address: null,
    number: null,
    neighborhood: null,
    complement: null,
    state: null,
    city: null,

    cardNumber: null,
    dateValidate: null,
    securityCode: null,
    fullName: null,
    taxpayerIdOrEin: null,
    dateOfBirth: null,
    phone: null,

    installments: {
      numberOfInstallments: null
    }
  },

  successPaymentCardData: {
    icon: 'icon-checkout-check',
    backgroundIcon: '#00d68f',
    title: 'Pagamento efetuado com sucesso!',
    transaction: {
      id: '',
      date: ''
    }
  },

  qrCodeIsExpired: false
};

const actions = {
  getCheckout({state}) {
    return new Promise((resolve, reject) => {
      instance().get('/checkouts/' + state.checkoutId)
        .then(res => {
          resolve(res.data);
        }).catch(err => {
          reject(err.response);
        });
    });
  },

  makePayment({commit, state}, data) {
    return new Promise((resolve, reject) => {
      instance().post('/checkouts/' + state.checkoutId + '/pay', data)
        .then(res => {
          commit('resetData');

          resolve(res.data);
        }).catch(err => {
          commit('resetData');

          reject(err.response);
        });
    });
  },

  getPostalCode(_, postalCode) {
    return new Promise((resolve, reject) => {
      instance().get('/postal-codes/' + postalCode,)
        .then(res => {
          resolve(res.data);
        }).catch(err => {
          reject(err.response);
        });
    });
  }
};

const mutations = {
  changeSpinnerStatus(state, status) {
    state.hasSpinner = status;
  },

  setFaviconPath(state, status) {
    state.faviconPath = status;
  },

  changeErrorStatus(state, status) {
    state.hasError = status;
  },

  setCurrentCheckoutId(state, id) {
    state.checkoutId = id;
  },

  resetData(state) {
    state.data.email = null;
    state.data.postalCode = null;
    state.data.address = null;
    state.data.number = null;
    state.data.cardNumber = null;
    state.data.dateValidate = null;
    state.data.securityCode = null;
    state.data.fullName = null;
    state.data.cpf = null;
    state.data.dateOfBirth = null;
    state.data.phone = null;
    state.neighborhood = null;
    state.complement = null;
  },

  resetInstallmentsData(state) {
    state.data.installments = {
      numberOfInstallments: null
    };
  },

  resetPaymentMethodData(state) {
    state.data.cardNumber = null;
    state.data.dateValidate = null;
    state.data.securityCode = null;
    state.data.fullName = null;
    state.data.taxpayerIdOrEin = null;
    state.data.dateOfBirth = null;
    state.data.phone = null;
  },

  resetFormStatus(state) {
    state.formStepStatus = {
      disableButton: true,
      disableToPayForm: false,
      disableUserDataForm: true,
      disablePaymentMethodForm: true,

      firstStep: 'wait',
      secondStep: 'default',
      thirdStep: 'default',
      fourthStep: 'default'
    };
  },

  disableAllForms(state) {
    state.formStepStatus.disableButton = true;
    state.formStepStatus.disableUserDataForm = true;
    state.formStepStatus.disablePaymentMethodForm = true;
    state.formStepStatus.disableToPayForm = true;
  },

  setQrCodeIsExpired(state, status) {
    state.qrCodeIsExpired = status;
  }
};

const getters = {
  getCurrentCheckoutId(state) {
    return state.checkoutId;
  },

  getFormStepStatus(state) {
    return state.formStepStatus;
  },

  getCheckoutStatus(state) {
    return state.checkoutStatus;
  },

  getSuccessPaymentCardData(state) {
    return state.successPaymentCardData;
  },

  getQrCodeIsExpired(state) {
    return state.qrCodeIsExpired;
  },

  getFaviconPath(state) {
    return state.faviconPath;
  }
};

const store = new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules: {
    ecommerceModule
  }
});

export default store;