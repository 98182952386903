<template>
  <div class="to-pay-card-container">
    <default-section title="Forma de pagamento">
      <template v-slot:content>
        <div class="content">
          <div class="value-output">
            Valor total

            <div class="value">
              {{ grandValueReal }}
            </div>
          </div>

          <div class="value-input">
            <label>
              Número de parcelas

              <span class="required">
                *
              </span>
            </label>

            <div class="value">
              <select :style="{ 'background': arrowDown }" v-model="numberOfInstallments">
                <option hidden selected value="">
                  Escolha as parcelas
                </option>

                <option v-for="item in installments" :value="item" :key="item.id">
                  {{ item.option }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </template>
    </default-section>
  </div>
</template>

<script>
  import Arrow from '@/assets/icons/arrow-down-select.svg';
  import DefaultSection from '@/components/base/DefaultSection';
  import { mapState } from 'vuex';

  export default {
    name: 'ToPayCard',

    data() {
      return {
        arrow: Arrow,
        grandValue: '',
        grandValueReal: '',
        numberOfInstallments: '',
        maxInstallments: 12,
        amountInstallment: 0,
        minValueInstallment: 25,
        installments: [],
      };
    },

    props: {
      data: {
        type: Object,
        required: true,
      }
    },

    watch: {
      numberOfInstallments() {
        this.paymentData.installments.numberOfInstallments = this.numberOfInstallments.id;
        this.selected();
      }
    },

    components: {
      'default-section': DefaultSection
    },

    computed: {
      ...mapState({
        paymentData: state => state.data,
        formStepStatus: state => state.formStepStatus
      }),

      arrowDown() {
        return 'url('+ `${this.arrow}` + ') #FFF no-repeat 92%';
      }
    },

    mounted() {
      const metadata = this.data.metadata;

      if (metadata?.resource && this.$utils.toUpperCase(metadata.resource) == 'CHECKOUTEDITORA') {
        this.minValueInstallment = this.data?.min_installment_amount || 200;
      }

      this.grandValue = this.data.grand_total;
      this.grandValueReal = this.$utils.formatBrlAmount(this.grandValue);
      this.maxInstallments = this.data.max_installments >= 12 ? 12 : this.data.max_installments;

      if (this.grandValue >= this.minValueInstallment) {
        for (var i = 1; i <= this.maxInstallments; i++) {
          let valuePerInstallment = this.grandValue / i;

          if (valuePerInstallment >= this.minValueInstallment) {
            let formattedValueInstallment = this.$utils.formatBrlAmount(valuePerInstallment);

            this.installments.push({
              id: i,
              amount: this.grandValue,
              option: i + ' x de ' + formattedValueInstallment
            });
          }
        }
      } else {
        this.installments.push({
          id: 1,
          amount: this.grandValue,
          option: 1 + ' x de ' + this.grandValueReal
        });
      }
    },

    methods: {
      selected() {
        this.formStepStatus.secondStep = 'check';
        this.formStepStatus.thirdStep = 'wait';
        this.formStepStatus.disableUserDataForm = false;
      },

      minValueOnArray(array) {
        return Math.min.apply(Math, array);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .to-pay-card-container {
    .content {
      margin-top: 1.5rem;
      display: flex;
      font-size: 14px;

      .value-output {
        margin-right: 1rem;

        .value {
          padding: 1rem;
          width: 15rem;
          height: 48px;
          background: #dadada;
          border-radius: 0.5rem;
          margin-top: 0.5rem;
          font-weight: 600;
          font-size: 20px;
          display: flex;
          align-items: center;
        }
      }

      .value-input {
        .required {
          color: #b81d5b;
        }

        .value {
          select {
            -webkit-appearance: none;
            -moz-appearance: none;
            padding: 0 1rem;
            width: 15rem;
            font-size: 1rem;
            height: 48px;
            border: none;
            border-radius: 0.5rem;
            border: 2px solid #e3e3e3;
            margin-top: 0.5rem;
            appearance: none;
            background-position-x: 90%;
            outline: 0;
            font-size: 14px;
          }
        }
      }
    }
  }

  @media (max-width: 1120px) {
    .to-pay-card-container {
      .content {
        flex-direction: column;

        .value-input {
          margin-top: 1rem;
        }
      }
    }
  }

  @media (max-width: 520px) {
    .to-pay-card-container {
      .content {
        font-size: 12px;

        .value-output {
          margin-right: unset;

          .value {
            width: 100%;
            font-size: 16px;
          }
        }

        .value-input {
          .value {
            select {
              width: 100%;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
</style>