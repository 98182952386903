<template>
  <ValidationProvider
    tag="div"
    :vid="vid"
    :name="name"
    :rules="rules"
    class="select-input-container"
    :class="styles"
    v-slot="{ errors, required }"
  >
    <p>
      {{ label }}

      <span v-if="required">
        *
      </span>
    </p>

    <div class="input-container">
      <select
        :class="{ 'error': !!errors[0] && !disable, 'disable': disable }"
        :style="{ 'background': arrowDown }"
        :disabled="disable"
        v-model="innerValue"
      >
        <option hidden selected value="">
          {{ placeholder }}
        </option>

        <option
          v-for="(option, index) in options"
          :value="option.value"
          :key="index"
        >
          {{ option.label }}
        </option>
      </select>

      <span v-if="!!errors[0] && !disable">
        {{ errors[0] }}
      </span>
    </div>
  </ValidationProvider>
</template>

<script>
  import Arrow from '@/assets/icons/arrow-down-select.svg';
  import { ValidationProvider } from 'vee-validate';

  export default {
    name: 'BaseSelectInputField',

    props: {
      vid: {
        type: String,
        default: undefined
      },

      styles: {
        type: String,
        default: ''
      },

      placeholder: {
        type: String,
        default: 'Escolha as parcelas'
      },

      label: {
        type: String,
        default: ''
      },

      rules: {
        type: [Object, String],
        default: ''
      },

      disable: {
        type: Boolean,
        default: false
      },

      refName: {
        type: String,
        required: false
      },

      options: {
        type: Array,
        required: false,
      },
    },

    components: {
      ValidationProvider,
    },

    data() {
      return {
        arrow: Arrow,
        name: null,
        innerValue: '',
      };
    },

    computed: {
      arrowDown() {
        return 'url('+ `${this.arrow}` + ') #FFF no-repeat 97%';
      }
    },

    watch: {
      innerValue(value) {
        this.$emit('input', value);
      },

      value(value) {
        this.innerValue = value;
      },

      options(value) {
        if (value.length === 0) {
          this.innerValue = '';
        }
      },
    },

    created() {
      if (this.value) {
        this.innerValue = this.value;
      }
    },

    mounted() {
      this.name = this.refName ?? this.label.toLowerCase();
    },
  };
</script>

<style lang="scss" scoped>
  .select-input-container {
    width: 100%;

    &.large {
      max-width: 20rem;
    }

    &.medium {
      max-width: 15rem;
    }

    &.small {
      max-width: 11rem;
    }

    span {
      color: #b93a5b;
      font-size: 12px;
    }

    p {
      margin-bottom: 0.5rem;
    }

    .input-container {
      position: relative;

      select {
        width: 100%;
        height: 48px;
        padding: 0 1rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 14px;
        color: #333;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        cursor: pointer;

        &.error {
          border: 2px solid #b93a5b;
        }

        &.disable {
          opacity: 0.6;
          cursor: not-allowed
        }
      }

      span {
        margin-top: 0.2rem;
        display: flex;
      }
    }
  }
</style>