<template>
  <footer :style="styleObject">
    © Copyright {{ currentFullYear }} {{ data.name }} - Todos os direitos reservados.
  </footer>
</template>

<script>
  export default ({
    name: 'CustomFooter',

    data() {
      return {
        currentFullYear: '',
      };
    },

    props: {
      data: {
        type: Object,
        default() {
          return {
            backgroundColor: '#435970',
            name: 'IM Pay',
            fontColor: '#FFFFFF',
          };
        }
      }
    },

    computed: {
      styleObject: function () {
        return {
          '--background-color': this.data.backgroundColor,
          '--color': this.data.fontColor || '#FFFFFF',
        };
      }
    },

    mounted() {
      let date = new Date();

      this.currentFullYear = date.getFullYear();
    },
  });
</script>

<style lang="scss" scoped>
  footer {
    background: var(--background-color);
    color: var(--color);
    border-top: 2px solid #DADADA33;
    min-width: 320px;
    height: 50px;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    margin-top: auto;
    padding: 0 1rem;
  }

  @media (max-width: 480px) {
    footer {
      font-size: 12px;
    }
  }
</style>