<template>
  <button :class="disableButton ? 'disable' : 'enable'" :disabled="disableButton">
    <spinner-component
      v-if="hasRequest"
      :styles="buttonSpinner"
    />

    <div v-else>
      {{ text }}
    </div>
  </button>
</template>

<script>
  import Spinner from '@/components/Spinner';

  export default {
    name: 'CustomButton',

    components: {
      'spinner-component': Spinner,
    },

    data() {
      return {
        buttonSpinner: {
          size: '3px',
          color: '#000',
          borderSize: '1.5em',
        }
      };
    },

    props: {
      hasRequest: {
        type: Boolean,
        default: false
      },

      disableButton: {
        type: Boolean,
        default: false
      },

      text: {
        type: String,
        default: 'Text'
      }
    }
  };
</script>

<style lang="scss" scoped>
  button {
    width: 200px;
    height: 48px;
    border-radius: 2rem;
    border: none;
    font-size: 18px;
    font-weight: 600;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &.enable {
      cursor: pointer;
      background: #ff9c00;
      color: #FFFFFF;

      &:hover {
        background: #e28a00;
      }

      &:active {
        background: #bf7500;
      }
    }

    &.disable {
      cursor: not-allowed;
      background: #dadada;
      color: #000;
      user-select: none;
      opacity: 0.4;
    }
  }

  @media (max-width: 950px) {
    button {
      font-size: 16px;
    }
  }

  @media (max-width: 520px) {
    button {
      font-size: 14px;
    }
  }
</style>