<template>
  <ValidationProvider
    tag="div"
    :vid="vid"
    :name="name"
    :rules="rules"
    class="input-container"
    :class="styles"
    v-slot="{ errors, required }"
  >
    <div>
      <p>
        {{ label }}

        <span v-if="required">
          *
        </span>
      </p>
    </div>

    <div class="input-container">
      <input
        v-if="hasCardBrand"
        :class="{ 'error': !!errors[0], 'disable': disable, 'brand': hasCardBrand }"
        :style="{ 'background': cardBrandImage, 'background-size': '40px'}"
        :type="inputType"
        :value="value"
        :placeholder="placeholder"
        :disabled="disable"
        autocomplete="off"
        @blur="handleBlur"
        v-model="innerValue"
      />

      <input
        v-else-if="!!mask"
        v-mask="mask"
        :class="{ 'error': !!errors[0], 'disable': disable }"
        :type="inputType"
        :value="value"
        :placeholder="placeholder"
        :disabled="disable"
        :maxlength="maxLength"
        autocomplete="off"
        @blur="handleBlur"
        v-model="innerValue"
      />

      <input
        v-else
        :class="{ 'error': !!errors[0], 'disable': disable }"
        :type="inputType"
        :value="value"
        :placeholder="placeholder"
        :disabled="disable"
        :maxlength="maxLength"
        autocomplete="off"
        @blur="handleBlur"
        v-model="innerValue"
      />

      <span v-if="!!errors[0]">
        {{ errors[0] }}
      </span>
    </div>
  </ValidationProvider>
</template>

<script>
  import MasterDefault from '@/assets/brands/master-default.svg';
  import Master from '@/assets/brands/master.svg';
  import Elo from '@/assets/brands/elo.svg';
  import Visa from '@/assets/brands/visa.svg';
  import Aura from '@/assets/brands/aura.png';
  import Jcb from '@/assets/brands/jcb.svg';
  import Amex from '@/assets/brands/amex.svg';
  import Discover from '@/assets/brands/discover.svg';
  import BanesCard from '@/assets/brands/banescard.png';
  import Hiper from '@/assets/brands/hiper.svg';
  import HiperCard from '@/assets/brands/hipercard.png';
  import Cabal from '@/assets/brands/cabal.svg';
  import DinersClub from '@/assets/brands/diners.svg';
  import { ValidationProvider } from 'vee-validate';

  export default {
    name: 'BaseInputField',

    props: {
      type: {
        type: String,
        default: 'text',
        validator(value) {
          return [
            'text',
            'number',
          ].includes(value);
        },
      },

      vid: {
        type: String,
        default: undefined
      },

      placeholder: {
        type: String,
        required: true
      },

      label: {
        type: String,
        default: ''
      },

      styles: {
        type: String,
        default: ''
      },

      rules: {
        type: [Object, String],
        default: ''
      },

      value: {
        type: null,
        required: ''
      },

      maxLength: {
        type: Number,
        required: false
      },

      hasCardBrand: {
        type: Boolean,
        required: false
      },

      mask: {
        type: [Array, String],
        required: false
      },

      refName: {
        type: String,
        required: false
      },

      disable: {
        type: Boolean,
        default: false
      }
    },

    components: {
      ValidationProvider,
    },

    data() {
      return {
        name: null,
        innerValue: null,
        inputType: 'text',

        path: MasterDefault,
        master: Master,
        visa: Visa,
        elo: Elo,
        aura: Aura,
        jcb: Jcb,
        amex: Amex,
        discover: Discover,
        banesCard: BanesCard,
        hiper: Hiper,
        hiperCard: HiperCard,
        cabal: Cabal,
        diners: DinersClub,
        masterDefault: MasterDefault,
      };
    },

    created() {
      this.inputType = this.type;

      if (this.value) {
        this.innerValue = this.value;
      }
    },

    mounted() {
      this.name = this.refName ?? this.label.toLowerCase();
    },

    computed: {
      cardBrandImage() {
        return `url(${this.path}) #FFFFFF no-repeat 95%`;
      }
    },

    watch: {
      innerValue(value) {
        if (this.hasCardBrand) {
          this.innerValue =  value.replace(/\s+/g, '');
        }

        this.$emit('input', value);

        const cardBrand = this.$utils.getCardBrand(value);
        this.path = cardBrand ? this.identifyCardBrand(cardBrand.name) : this.masterDefault;
      },

      value(value) {
        if (value === this.innerValue) return;

        this.innerValue = value;
      }
    },

    methods: {
      identifyCardBrand(flag) {
        switch(flag) {
          case 'visa':
            return this.visa;
          case 'master':
            return this.master;
          case 'elo':
            return this.elo;
          case 'aura':
            return this.aura;
          case 'jcb':
            return this.jcb;
          case 'amex':
            return this.amex;
          case 'hiper':
            return this.hiper;
          case 'banescard':
            return this.banesCard;
          case 'hipercard':
            return this.hiper;
          case 'discover':
            return this.discover;
          case 'cabal':
            return this.cabal;
          case 'diners':
            return this.diners;
          default:
            return this.masterDefault;
        }
      },

      handleBlur() {
        this.$emit('blur', true);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .input-container {
    width: 100%;

    &.large {
      max-width: 20rem;
    }

    &.medium {
      max-width: 15rem;
    }

    &.small {
      max-width: 11rem;
    }

    p {
      margin-bottom: 0.5rem;
      font-size: 14px;
    }

    span {
      color: #b93a5b;
      font-size: 12px;
    }

    .input-container {
      input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 100%;
        height: 48px;
        border: 1px solid #DADADA;
        background: #FFFFFF;
        border-radius: 6px;
        padding: 1rem;
        font-size: 14px;

        &[type="number"]::-webkit-inner-spin-button,
        &[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &.disable {
          opacity: 0.6;
          cursor: not-allowed
        }

        &.error {
          border: 2px solid #b93a5b;
        }

        &.brand {
          padding: 1rem 4rem 1rem 1rem;
        }
      }

      .icon-container {
        color: #8E8E8E;;
        float: right;
        position: relative;
        bottom: 35px;
        right: 15px;
        height: 0;

        i {
          font-size: 1.5rem;
        }
      }

      span {
        color: #b93a5b;
        margin-top: 0.2rem;
        display: flex;
      }
    }
  }

  @media (max-width: 1350px) {
    .input-container {
      &.large {
        max-width: 18rem;
      }

      &.medium {
        max-width: 13rem;
      }
    }
  }

  @media (max-width: 1280px) {
    .input-container {
      &.large {
        max-width: 100%;
      }

      &.medium {
        max-width: 100%;
      }

      &.small {
        max-width: 100%;
      }
    }
  }

  @media (max-width: 520px) {
    .input-container {
      span {
        font-size: 10px;
      }

      p {
        font-size: 12px;
      }

      .input-container {
        input {
          font-size: 12px;
        }
      }
    }
  }
</style>