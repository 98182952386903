<template>
  <div class="pix-card-container">
    <default-section title="Meio de pagamento - Pix">
      <template v-slot:content>
        <div v-if="hasQrCode" class="content has-qr-code">
          <div class="instructions-container">
            <div class="title">
              Como realizar o pagamento:
            </div>

            <div class="step-container">
              <div v-for="(item, index) in steps" :key="index" class="step">
                <div class="number-container">
                  <div class="number">
                    {{ item.step }}
                  </div>
                </div>

                <div class="text">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>

          <div class="qr-code-card-container">
            <qr-code-card-component
              :emvValue="emv"
              :hasQrCodeRequest="hasRequest"
            />
          </div>
        </div>

        <div v-else class="content">
          <div class="value-output">
            Valor total

            <div class="value">
              {{ grandValueReal }}
            </div>
          </div>

          <div class="button-container">
            <button-component
              class="button"
              text="Gerar QR Code"
              :hasRequest="hasRequest"
              :disableButton="hasRequest"
              @click.native="generateQrCode"
            />
          </div>
        </div>
      </template>
    </default-section>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import DefaultSection from '@/components/base/DefaultSection';
  import Button from '@/components/Button';
  import QrCodeCard from '@/components/QrCodeCard';

  export default {
    name: 'PixPaymentCard',

    data() {
      return {
        hasQrCode: false,
        hasRequest: false,
        grandValueReal: 0,
        checkoutData: {},
        pixTransactionStatus: null,
        emv: '',
        steps: [
          {
            text: 'Abra o aplicativo do seu banco ou instituição finaceira e selecione Pix',
            step: 1
          },
          {
            text: 'Escaneie o QR Code ou a use a opção copia e cola',
            step: 2
          },
          {
            text: 'Cofirme as informações e finalize o pagamento',
            step: 3
          }
        ]
      };
    },

    props: {
      value: {
        type: Number,
        default: 0
      }
    },

    components: {
      'default-section': DefaultSection,
      'button-component': Button,
      'qr-code-card-component': QrCodeCard
    },

    computed: {
      ...mapState({
        formStepStatus: state => state.formStepStatus,
        checkoutStatus: state => state.checkoutStatus,
        successPaymentCardData: state => state.successPaymentCardData,
        qrCodeIsExpired: state => state.qrCodeIsExpired,
        paymentData: state => state.data
      })
    },

    watch: {
      qrCodeIsExpired(value) {
        if(!value) {
          this.setPayment();
        }
      }
    },

    mounted() {
      this.grandValueReal = this.$utils.formatBrlAmount(this.value);
    },

    methods: {
      generateQrCode() {
        this.formStepStatus.secondStep = 'check';
        this.setPayment();
      },

      setPayment() {
        this.hasRequest = true;

        this.$store.dispatch('getCheckout')
          .then(res => {
            this.checkoutData = res.data;

            if (this.checkoutData.transaction != null && this.$utils.toUpperCase(this.checkoutData.transaction.status) == 'SUCCEEDED') {
              this.checkoutStatus.isPayment = false;
              this.checkoutStatus.isSuccessPayment = true;
              this.successPaymentCardData.transaction.id = this.checkoutData.transaction.id;
              this.successPaymentCardData.transaction.date = this.$utils.dateFormat(this.checkoutData.transaction.updated_at, 'DD/MM/YYYY HH:mm:ss');
            } else {
              this.$store.commit('setQrCodeIsExpired', false);

              if (this.checkoutData.transaction != null && !this.checkoutData.transaction.payment.pix.is_expired) {
                this.hasRequest = false;
                this.hasQrCode = true;
                this.checkoutStatus.isPayment = true;
                this.emv = this.checkoutData.transaction.payment.pix.qr_code.emv;
                this.paymentData.installments.numberOfInstallments = 1;

                this.verifyPaymentStatus();
              } else {
                this.createPayment();
              }
            }
          });
      },

      async createPayment() {
        this.hasRequest = true;

        let payment = JSON.stringify({
          payment: {
            method: 'Pix'
          }
        });

        await this.$store.dispatch('makePayment', payment)
          .then(res => {
            this.hasQrCode = true;
            this.checkoutStatus.isPayment = true;
            this.emv = res.data.transaction.payment.pix.qr_code.emv;
            this.paymentData.installments.numberOfInstallments = 1;
            this.verifyPaymentStatus();
          })
          .catch(err => {
            this.checkoutStatus.isPayment = false;
            this.checkoutStatus.isPixPaymentError = true;
            this.checkoutStatus.isExpired = false;

            if (err.data && err.data.status == 419) {
              this.checkoutStatus.isExpired = true;
            }
          });

          this.hasRequest = false;
      },

      verifyPaymentStatus() {
        this.$store.commit('setQrCodeIsExpired', false);

        this.pixTransactionStatus = setInterval(async() => {
          await this.$store.dispatch('getCheckout')
            .then(res => {
              this.checkoutData = res.data;
              let isExpiredPix = this.checkoutData.transaction.payment.pix.is_expired;

              if (this.checkoutData.is_expired) {
                this.checkoutStatus.isExpired = true;
              }

              if (this.checkoutData.transaction != null && this.checkoutData.transaction.status === 'Succeeded' && !isExpiredPix) {
                this.checkoutStatus.isPayment = false;
                this.checkoutStatus.isSuccessPayment = true;
                this.successPaymentCardData.transaction.id = this.checkoutData.transaction.id;
                this.successPaymentCardData.transaction.date = this.$utils.dateFormat(this.checkoutData.transaction.updated_at, 'DD/MM/YYYY HH:mm:ss');
              } else if (isExpiredPix) {
                this.$store.commit('setQrCodeIsExpired', true);
                clearInterval(this.pixTransactionStatus);
              }
            })
            .catch(() => {
              this.$store.commit('setQrCodeIsExpired', true);
              clearInterval(this.pixTransactionStatus);
            });
        }, 5000);
      }
    },

    beforeDestroy() {
      clearInterval(this.pixTransactionStatus);
    }
  };
</script>

<style lang="scss" scoped>
  .pix-card-container {
    .content {
      margin-top: 1.5rem;
      display: flex;

      &.has-qr-code {
        .instructions-container {
          font-size: 14px;

          .title {
            font-weight: 500;
            font-size: 16px;
          }

          .step-container {
            margin-top: 0.5rem;
            padding: 1rem;

            .step {
              display: flex;
              margin-bottom: 1rem;

              .number-container {
                .number {
                  color: #FFFFFF;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-weight: 600;
                  height: 25px;
                  width: 25px;
                  background: #ff9c00;
                  border-radius: 100%;
                }
              }

              .text {
                margin-left: 0.5rem;
              }
            }
          }
        }

        .qr-code-card-container {
          margin-left: 3rem;
          align-items: center;
          justify-content: center;
          display: flex;
        }

        .logo-pix {
          margin-top: 2rem;
          justify-content: center;
          align-items: center;
          display: flex;

          img {
            width: 145px;
          }
        }
      }

      .value-output {
        margin-right: 1rem;
        font-size: 14px;

        .value {
          padding: 1rem;
          width: 15rem;
          height: 48px;
          background: #dadada;
          border-radius: 0.5rem;
          margin-top: 0.5rem;
          font-weight: 600;
          font-size: 20px;
          display: flex;
          align-items: center;
        }
      }

      .button-container {
        display: flex;
        align-items: flex-end;
        width: 15rem;

        .button {
          border-radius: 0.5rem;
          width: 100%;
        }
      }

      .pix-card-container {
        display: flex;
        justify-content: center;
      }
    }
  }

  @media (max-width: 1215px) {
    .pix-card-container {
      .content {
        flex-direction: column;

          &.has-qr-code {
            .qr-code-card-container {
              margin-left: unset;
              margin-top: 0.5rem;
            }
          }

        .button-container {
          margin-top: 1rem;
        }
      }
    }
  }

  @media (max-width: 520px) {
    .pix-card-container {
      .content {
        &.has-qr-code {
          .instructions-container {
            font-size: 12px;

            .title {
              font-size: 14px;
            }

            .step-container {
              .step {
                .number-container {
                  .number {
                    height: 20px;
                    width: 20px;
                  }
                }
              }
            }
          }
        }

        .value-output {
          margin-right: unset;
          font-size: 12px;

          .value {
            font-size: 16px;
            width: 100%;
          }
        }

        .button-container {
          width: 100%;
        }
      }
    }
  }
</style>