import { extend } from 'vee-validate';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { required, confirmed, min, max } from 'vee-validate/dist/rules';
import moment from 'moment';

extend('required', {
  ...required,
  message: 'Campo obrigatório',
});

extend('confirmed', {
  ...confirmed,
  message: 'Senhas não conferem',
});

extend('min', {
  ...min,
  message: 'Mín. {length} caracteres',
});

extend('max', {
  ...max,
  message: 'Máx. {length} caracteres',
});

extend('mail', {
  message: 'E-mail inválido',
  validate: value => {
    if (value != null) {
      let regEmail = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

      return !!value.match(regEmail);
    } else {
      return false;
    }
  }
});

extend('taxpayerId', {
  message: 'CPF inválido',
  validate: value => {
    if (value != null) {
      let strTaxpayerId  = value.replace(/\.|-/g,"");

      return cpf.isValid(strTaxpayerId);
    }
  }
});

extend('ein', {
  message: 'CNPJ inválido',
  validate: value => {
    if (value != null) {
      let strEin  = value.replace(/\.|-/g,"");

      return cnpj.isValid(strEin);
    }
  }
});

extend('taxpayerIdOrEin', {
  message: 'CPF/CNPJ inválido',
  validate: value => {
    if (value != null) {
      let number = value.replace(/\.|-/g,"");

      return number.length == 15 ? cnpj.isValid(number) : cpf.isValid(number);
    }
  }
});

extend('validCompletedName', {
  message: 'Digite o nome completo',
  validate: value => {
    if (value != null) {
      if (value.trim().indexOf(' ') >= 0) {
        let regName = /^[\p{L}'][ \p{L}'-]*[\p{L}]\s*$/u;

        return value.match(regName);
      }
    } else {
      return false;
    }
  }
});

extend('validExpirationCard', {
  message: 'Data inválida',
  validate: value => {
    if (value != null && value.length == 5) {
      let currentMonth = new Date().getMonth() + 1;
      let currentYear = new Date().getFullYear().toString().substr(-2);
      let validateMonth = value.toString().substr(0, 2);
      let validateYear = value.toString().substr(-2);

      if ((validateMonth >= currentMonth && validateYear >= currentYear)
          || (validateYear > currentYear)) {
        return moment(value, "MM-YY").isValid();
      }
    } else {
      return false;
    }
  }
});

extend('date', {
  message: 'Data de nascimento inválida',
  validate: value => {
    let valueChar = value.replace(/[^0-9]/g, '');

    if (value != null && valueChar.length === 8) {
      return moment(value, "DD-MM-YYYY").isValid();
    } else {
      return false;
    }
  }
});

extend('cpf', {
  message: 'CPF inválido',
  validate: value => {
    if (value != null) {
      let strCpf  = value.replace(/\.|-/g,"");

      return cpf.isValid(strCpf);
    }
  }
});

extend('completedName', {
  message: 'Digite o nome completo',
  validate: value => {
    if (value != null) {
      if (value.indexOf(' ') >= 0) {
        let regName = /^[\p{L}'][ \p{L}'-]*[\p{L}]$/u;

        return value.match(regName);
      }
    } else {
      return false;
    }
  }
});

extend('validExpirationCard', {
  message: 'Data inválida',
  validate: value => {
    if (value != null && value.length == 5) {
      let currentMonth = new Date().getMonth() + 1;
      let currentYear = new Date().getFullYear().toString().substr(-2);

      let validateMonth = value.toString().substr(0, 2);
      let validateYear = value.toString().substr(-2);

      if ((validateMonth >= currentMonth && validateYear >= currentYear)
         || (validateYear > currentYear)) {
        return moment(value, "MM-YY").isValid();
      }
    } else {
      return false;
    }
  }
});

extend('date', {
  message: 'Data de nascimento inválida',
  validate: value => {
    let valueChar = value.replace(/[^0-9]/g, '');

    if (value != null && valueChar.length === 8) {
      return moment(value, "DD-MM-YYYY").isValid();
    } else {
      return false;
    }
  }
});

extend('postalCode', {
  message: 'CEP inválido',
  validate: value => {
    if (value != null) {
      let valueChar = value.replace(/[^0-9]/g, '');

      return valueChar.length === 8;
    }
  }
});

/* Caution: Changing a regex, you must change it everywhere */
extend('card', {
  message: 'Cartão inválido e/ou não suportado',
  validate: value => {
    if (value != null) {
      let regCards = {
        visa: /^4[0-9]{12}(?:[0-9]{3})/,
        master: /^5[1-5]|^2(2(2[1-9]|[3-9])|[3-6]|7([01]|20))/,
        elo: /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^504175|^627780|^63(6297|6368|6369)|(65003[5-9]|65004[0-9]|65005[01])|(65040[5-9]|6504[1-3][0-9])|(65048[5-9]|65049[0-9]|6505[0-2][0-9]|65053[0-8])|(65054[1-9]|6505[5-8][0-9]|65059[0-8])|(65070[0-9]|65071[0-8])|(65072[0-7])|(65090[1-9]|6509[1-6][0-9]|65097[0-8])|(65165[2-9]|6516[67][0-9])|(65500[0-9]|65501[0-9])|(65502[1-9]|6550[34][0-9]|65505[0-8])|^(506699|5067[0-6][0-9]|50677[0-8])|^(509[0-8][0-9]{2}|5099[0-8][0-9]|50999[0-9])|^65003[1-3]|^(65003[5-9]|65004\d|65005[0-1])|^(65040[5-9]|6504[1-3]\d)|^(65048[5-9]|65049\d|6505[0-2]\d|65053[0-8])|^(65054[1-9]|6505[5-8]\d|65059[0-8])|^(65070\d|65071[0-8])|^65072[0-7]|^(65090[1-9]|65091\d|650920)|^(65165[2-9]|6516[6-7]\d)|^(65500\d|65501\d)|^(65502[1-9]|6550[3-4]\d|65505[0-8])/,
        hiper: '^606282|^637095|^637599|^637568',
        discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
        jcb: /^(?:2131|1800|35\d{3})\d{11}/,
        banese: /^636117/,
        amex: /^3[47][0-9]{13}/,
        aura: /^((?!504175))^((?!5067))(^50[0-9])/
      };

      for (var card in regCards) if (value.match(regCards[card])) return true;
      return false;
    }
  }
});

extend('cardNumber', {
  message: 'Cartão inválido e/ou não suportado',
  validate: value => {
    if (value != null) {
      let regCards = {
        visa: /^4[0-9]{15}$/,
        master: /^((5(([1-2]|[4-5])[0-9]{8}|0((1|6)([0-9]{7}))|3(0(4((0|[2-9])[0-9]{5})|([0-3]|[5-9])[0-9]{6})|[1-9][0-9]{7})))|((508116)\\d{4,10})|((502121)\\d{4,10})|((589916)\\d{4,10})|(2[0-9]{15})|(67[0-9]{14})|(506387)\\d{4,10})/,
        elo: /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])|^627780|^63(6297|6368|6369)|^65(0(0(3([1-3]|[5-9])|4([0-9])|5[0-1])|4(0[5-9]|[1-3][0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8]|4[1-9]|[5-8][0-9]|9[0-8])|7(0[0-9]|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|16(5[2-9]|[6-7][0-9])|50(0[0-9]|1[0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/,
        aura: /^((?!504175))^((?!5067))(^50[0-9])/,
        jcb: /^(?:2131|1800|35\d{3})\d{11}/,
        amex: /^3[47][0-9]{13}/,
        hiper: /^(38[0-9]{17}|60[0-9]{14})$/,
        banescard: /^(603182)[0-9]{10,12}/,
        hipercard: /^606282|^3841(?:[0|4|6]{1})0/,
        discover: /^(6011\d{12}|(62212[6-9]|6221[3-9]\d|622[2-8]\d\d|6229[01]\d|62292[0-5])\d{10}|64[4-9]\d{13}|65\d{14})$/,
        cabal: /^(604\d{13}|589657\d{10}|627170\d{10})$/,
        diners: /^((300|301|302|303|304|305)\d{11}|36\d{12}|38\d{12})$/
      };

      for (var card in regCards) if (value.match(regCards[card])) return true;
      return false;
    }
  }
});

extend('phone', {
  message: 'Número de celular',
  validate: value => {
    if (value.length >= 18 && value.length <= 19) {
      let regName = /^(?:\+)[0-9]{2}\s?(?:\()[0-9]{2}(?:\))\s?[0-9]{4,5}(?:-)[0-9]{4}$/;

      return value.match(regName);
    }

    return false;
  }
});
