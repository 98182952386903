<template>
  <header :class="{ 'empty-text': !data.text }" :style="styleObject">
    <div class="logo-container" >
      <img :src="data.isExternalLogo ? data.logoPath : logoImport">
    </div>

    <div v-if="data.text" class="text-container">
      {{ data.text }}
    </div>
  </header>
</template>

<script>
  export default {
    name: 'CustomNavbar',

    props: {
      data: {
        type: Object,
        default() {
          return {
            backgroundColor: '#435970',
            logoPath: 'logo-impay.png',
            isExternalLogo: false,
            text: 'Sistema de pagamento digital'
          };
        }
      }
    },

    computed: {
      styleObject() {
        return {
          '--background-color': this.data.backgroundColor,
          '--border-color': this.data.borderColor
        };
      },

      logoImport() {
        return this.data.logoPath ? require(`@/assets/images/${this.data.logoPath}`) : require(`@/assets/images/logo-impay.png`);
      }
    },
  };
</script>

<style lang="scss" scoped>
  header {
    z-index: 1;
    background: var(--background-color);
    border-bottom: 2px solid var(--border-color);
    padding: 0.5rem 3rem;
    font-size: 14px;
    font-weight: 400;
    min-height: 85px;
    color: #FFFFFF;
    display: flex;
    align-items: center;

    .logo-container {
      position: absolute;
      margin-right: 2rem;
    }

    .text-container {
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      display: flex;
    }
  }

  @media (max-width: 1045px) {
    header {
      flex-direction: column;
      padding: 0.5rem ;

      &.empty-text {
        display: flex;
        align-items: center;
        justify-content: center;

        .logo-container {
          margin-bottom: 0;
        }
      }

      .logo-container {
        position: unset;
        margin-bottom: 1rem;
        justify-content: center;
        align-items: center;
        display: flex;
        margin-right: 0;

        img {
          width: 80%;
        }
      }

      .text-container {
        text-align: center;
      }
    }
  }

  @media (max-width: 480px) {
    header {
      font-size: 12px;
    }
  }
</style>